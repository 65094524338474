import React, { useState, useEffect, useContext, useRef } from 'react'
import { Link } from 'react-router-dom'
import useStyles from './styles'
import clsx from 'clsx'
import {
  View,
  ViewContext,
  SplitView,
  SplitViewPane,
  Text,
  Table,
  CardList,
  Board,
  FilterBar,
  Button,
  MenuPopover,
  InputField,
} from "shared/ui"
import {
  useMediaQuery,
  useTheme,
  Box,
} from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'
import {
  Add as AddIcon,
  ArrowBackIos as BackIcon,
  MoreVert as MoreIcon,
  VerticalSplit as SplitViewIcon,
  TableChart as TableViewIcon,
  Sort as SortIcon,
} from '@material-ui/icons'

function ListViewController(props) {
  const { loadMoreData, isFetching, lastFetched, data } = props
  const { selfLink, selectedKey, selectedIndex, renderDetailView } = props
  const { primaryFilterFields, secondaryFilterFields } = props
  const { filterButton, tableProps = {} } = props
  const { filterData, onFilterDataChange } = props
  const { renderRecordCount, renderEmptyAction } = props
  const { tableColumns, boardColumns, renderCardItem, cardFields, onItemClick, onMoveItem } = props
  const { showColumnHeader = false } = props
  const { enableSelection = false, enableIndicatorSelected = false, selectedCount = 0, isItemSelected, onSelectAll, onSelectItem } = props
  const { primaryButton, secondaryButtons, selectionButtons, showPrimaryButtonLast=false } = props
  const { sortFieldOptions, sortFieldValue, onSortFieldChange } = props
  const [viewStyle, setViewStyle] = useState(props.defaultViewStyle || "table")
  const viewContext = useContext(ViewContext)
  const { useHeader, isInModal = false, forceUseSideToggle = false } = props

  const classes = useStyles()
  const theme = useTheme()
  const smDown = useMediaQuery(theme.breakpoints.down("sm"))

  const useCardList = smDown || viewStyle === "split"

  const headerRef = useRef(0)
  const itemListRef = useRef([])

  const [isFetchingLoadMore, setIsFetchingLoadMore] = useState(false)

  useEffect(() => {
    if(!isFetching) setIsFetchingLoadMore(false)
  }, [isFetching])
  
  let actionButtons = []
  if (viewStyle === "split" || smDown) {
    if (primaryButton) {
      actionButtons.push({
        showLabel: false,
        color: "primary",
        ...primaryButton,
      })
    }
    if (secondaryButtons)
    actionButtons = showPrimaryButtonLast ? [...secondaryButtons, ...actionButtons] : [...actionButtons, ...secondaryButtons]
  }
  else {
    if (primaryButton) {
      actionButtons.push({
        variant: "contained",
        color: "primary",
        ...primaryButton,
      })
    }

    if (secondaryButtons)
      actionButtons = showPrimaryButtonLast ? [...secondaryButtons, ...actionButtons] : [...actionButtons, ...secondaryButtons]
  }

  // Add view style button
  let viewStyles = []
  if (props.allowedViewStyles)
    viewStyles = props.allowedViewStyles
  else {
    if (tableColumns)
      viewStyles.push("table")
    if (props.defaultViewStyle === "board")
      viewStyles.push("board")
    else if (cardFields || renderCardItem)
      viewStyles.push("split")
  }

  if (viewStyles.length > 1) {
    const i = viewStyles.indexOf(viewStyle)
    const nextViewStyle = viewStyles[i + 1 >= viewStyles.length ? 0 : i + 1]
    let icon = TableViewIcon
    if(nextViewStyle === "split") 
      icon = SplitViewIcon

    if (viewStyle === "split"){
      if(nextViewStyle == "table"){
        icon = TableViewIcon
      } else {
        icon = SplitViewIcon
      }
    }
    else if (viewStyle === "board")
      icon = SplitViewIcon

    actionButtons.push({
      icon,
      color: "primary",
      onClick: () => setViewStyle(nextViewStyle)
    })
  }

  if (actionButtons.length > 0 && actionButtons[actionButtons.length - 1].variant !== "contained")
    actionButtons[actionButtons.length - 1].insetRightMargin = true

  useEffect(() => {
    viewContext.setLoading(isFetching)
    return () => {
      viewContext.setLoading(false)
    }
  }, [isFetching])

  const handleOnScroll = (e) => {
    let load = false
    let topFirstPage = 0

    if(!isFetching && itemListRef.current.length > 0){
      let firstDataPage = data?.records?.[data?.records?.length - 20]
      let carditem = itemListRef.current[data?.records?.length - 20]?.getBoundingClientRect()
      topFirstPage = carditem?.top || 0
    }

    if (!load && !isFetching && topFirstPage < 0) {
      load = true
    } else if (Math.floor(Math.floor(e.target.scrollHeight) - Math.floor(e.target.scrollTop)) === Math.floor(e.target.clientHeight)) {
      // bottom
      load = true
    } else if (Math.floor(Math.floor(e.target.scrollHeight) - Math.floor(e.target.scrollTop)) === Math.floor(e.target.clientHeight) + 1) {
      // bottom
      load = true
    }

    if (load && loadMoreData){
      loadMoreData()
      setIsFetchingLoadMore(true)
    }
  }

  const renderCardList = () => {
    if (!cardFields && !renderCardItem)
      return
    if (lastFetched)
      return (
        <div className={classes.listViewCardListInset}>
          <CardList
            fields={cardFields}
            renderCardItem={renderCardItem}
            itemListRef={itemListRef}
            items={data.records}
            selectedIndex={selectedIndex}
            isFetching={isFetching && isFetchingLoadMore}
            skeleton={isFetching && !isFetchingLoadMore ? 3 : undefined}
            onCardClick={onItemClick}
            showCheckBox={enableSelection}
            onRowSelect={onSelectItem}
            isRowSelected={isItemSelected}
          />
        </div>
      )
    else if (isFetching)
      return (
        <div className={classes.listViewCardListInset}>
          <CardList
            fields={cardFields}
            renderCardItem={renderCardItem}
            skeleton={3}
          />
        </div>
      )
  }

  const renderTable = () => {
    if (!tableColumns)
      return
    if (lastFetched)
      return (
        <div className={classes.listViewTableInset}>
          <Table
            itemListRef={itemListRef}
            columns={tableColumns}
            rows={data.records}
            showColumnHeader={showColumnHeader}
            isFetching={isFetching && isFetchingLoadMore}
            skeleton={isFetching && !isFetchingLoadMore ? 3 : undefined}
            onRowClick={onItemClick}
            showCheckBox={enableSelection}
            showIndicatorSelected={enableIndicatorSelected}
            onRowSelect={onSelectItem}
            isRowSelected={isItemSelected}
            columnHeaderStyle={{
              top: headerRef?.current?.clientHeight,
              backgroundColor: 'white'
            }}
            {...tableProps}
          />
        </div>
      )
    else if (isFetching)
      return (
        <div className={classes.listViewTableInset}>
          <Table
            columns={tableColumns}
            skeleton={3}
            showColumnHeader={showColumnHeader}
            showCheckBox={enableSelection}
            {...tableProps}
          />
        </div>
      )
  }


  const renderBoard = () => {
    if (!boardColumns)
      return
    if (lastFetched)
      return (
        <div className={classes.listViewBoardInset}>
          <Board
            columns={boardColumns}
            cardFields={cardFields}
            renderCardItem={renderCardItem}
            items={data.records}
            selectedIndex={selectedIndex}
            isFetching={isFetching}
            onCardClick={onItemClick}
            onMoveItem={onMoveItem}
            itemListRef={itemListRef}
          />
        </div>
      )
    // else if (isFetching)
    //   return (
    //     <div className={classes.listViewBoardInset}>
    //       <Board
    //         columns={boardColumns}
    //         cardFields={cardFields}
    //         renderCardItem={renderCardItem}
    //         skeleton={3}
    //         />
    //     </div>
    //   )
  }

  const renderFilterBar = () => {
    if (primaryFilterFields || secondaryFilterFields)
      return (
        <div className={clsx(
          classes.listViewFilterBar,
          useCardList && classes.listViewFilterBarCardInset,
        )}>
          <FilterBar
            primaryFields={primaryFilterFields}
            secondaryFields={secondaryFilterFields}
            filterData={filterData}
            onFilterDataChange={onFilterDataChange}
          />
        </div>
      )
  }

  const renderFilterButton = () => {
    if (filterButton)
      return (
        <div className={classes.listViewFilterButton}>
          {filterButton.map(item =>
            <Button badge={item.badge} size="small">
              {item.name}
            </Button>
          )}
        </div>
      )
  }

  const renderNavigationBar = () => {
    let selectedSortField
    if (sortFieldOptions && sortFieldOptions.length > 0)
      selectedSortField = sortFieldOptions.find(field => field.name === sortFieldValue) || sortFieldOptions[0]

    return (
      <div className={classes.listViewNavigationBar}>
        {lastFetched && selectedCount <= 0 &&
          <Text variant="body2" color="textMuted">
            {renderRecordCount ? renderRecordCount(data) :
              <>
                {data.total} records
              </>
            }
          </Text>
        }
        {lastFetched && selectedCount > 0 &&
          <>
            <Text variant="body2" color="textPrimary">
              {selectedCount} of {data.total} records selected
            </Text>
            <div className={classes.listViewNavigationBarItemSpacing} />
            <Button variant="text" color="primary" size="small"
              onClick={() => onSelectAll(true)}
            >
              Select All
            </Button>
            <Button variant="text" color="primary" size="small"
              onClick={() => onSelectAll(false)}
            >
              Clear Selection
            </Button>
          </>
        }
        {!lastFetched && isFetching &&
          <Text variant="body2" color="textMuted">
            <Skeleton />
          </Text>
        }
        <div style={{ flexGrow: 1 }} />
        {lastFetched && selectedCount > 0 ?
          selectionButtons && selectionButtons.map(button => (
            <Button
              size="small"
              variant="contained"
              {...button}
            />
          )).reduce((p, n) =>
            p ?
              [...p, <div className={classes.listViewNavigationBarItemSpacing} />, n]
              : [n], null
          )
          // : selectedSortField &&
          //   <>
          //     <Button kind="sort" label={selectedSortField.label} size="small" insetRightMargin={true} />          
          //   </>
          : sortFieldOptions && sortFieldOptions.length > 0 &&
          <InputField
            type="option"
            options={sortFieldOptions}
            value={sortFieldValue}
            variant="clean"
            fullWidth={false}
            size="small"
            icon={SortIcon}
            hideOptionArrow={true}
            onChange={onSortFieldChange}
          />
        }
      </div>
    )
  }

  if (viewStyle === "split" && !smDown)
    return (
      <SplitView title={props.title} fixedPosition={true}>
        <SplitViewPane pane="side" width="large" fixedPosition={true}
          onScroll={handleOnScroll}
        >
          <View
            title={props.title}
            actionButtons={actionButtons}
          >
            {renderFilterBar()}
            {renderFilterButton()}
            {renderNavigationBar()}
            {renderCardList()}
          </View>
        </SplitViewPane>
        <SplitViewPane pane="content" fixedPosition={true}>
          <ViewContext.Provider value={{
            ...viewContext,
            breadcrumbs: []
          }}>
            {/*
              Always add content pane eventhough selectedKey is null
              to add divider line
            */}
            {selectedKey && renderDetailView(selectedKey)}
          </ViewContext.Provider>
        </SplitViewPane>
      </SplitView>
    )
  else if (viewStyle === "board")
    return (
      <ViewContext.Provider value={{
        ...viewContext,
        breadcrumbs: (smDown && selectedKey) ? [
          {
            title: smDown ? '' : props.title,
            linkTo: selfLink,
            icon: BackIcon,
            insetLeftMargin: true,
          }
        ] : forceUseSideToggle ? [...viewContext.breadcrumbs,] : [],
        // // Disable show/hide menu bar on list/detail view mode
        // breadcrumbs: [],
      }}>
        {
          (smDown && selectedKey) ? 
          renderDetailView(selectedKey) :
          <View
            title={props.title}
            actionButtons={actionButtons}
            onScroll={handleOnScroll}
          >
            {renderFilterBar()}
            {renderFilterButton()}
            {renderNavigationBar()}
            {renderBoard()}
          </View>
        }
      </ViewContext.Provider>
    )
  else if (viewStyle === "cardList")
    return (
      <ViewContext.Provider value={{
        ...viewContext,
        // Disable show/hide menu bar on list/detail view mode
        breadcrumbs: [],
      }}>
        <View
          title={props.title}
          actionButtons={actionButtons}
          useHeader={useHeader}
          fixedPosition={true}
          isInModal={isInModal}
          onScroll={handleOnScroll}
        >
          {renderFilterBar()}
          {renderFilterButton()}
          {renderNavigationBar()}
          {renderCardList()}
        </View>
      </ViewContext.Provider>
    )
  else if (selectedKey)
    return (
      <div>
        <ViewContext.Provider value={{
          ...viewContext,
          breadcrumbs: [
            // Disable show/hide menu bar on list/detail view mode
            // ...viewContext.breadcrumbs,
            {
              title: smDown ? '' : props.title,
              linkTo: selfLink,
              icon: BackIcon,
              insetLeftMargin: true,
            }
          ]
        }}>
          {renderDetailView(selectedKey)}
        </ViewContext.Provider>
      </div>
    )
  else
    return (
      <ViewContext.Provider value={{
        ...viewContext,
        // Disable show/hide menu bar on list/detail view mode
        breadcrumbs: forceUseSideToggle ? [...viewContext.breadcrumbs,] : [],
      }}>
        <View
          title={props.title}
          actionButtons={actionButtons}
          useHeader={useHeader}
          fixedPosition={true}
          isInModal={isInModal}
          onScroll={handleOnScroll}
          headerRef={headerRef}
        >
          {renderFilterBar()}
          {renderFilterButton()}
          {renderNavigationBar()}
          {smDown ? renderCardList() : renderTable()}
          {filterData?.q && data?.records?.length < 1 && !isFetching && renderEmptyAction && renderEmptyAction()}
        </View>
      </ViewContext.Provider>
    )
}

export default ListViewController