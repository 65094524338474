import { useState, useContext } from 'react'
import useStyles from './styles'
import {
  SplitView,
  SplitViewPane,
  ListView,
  ListHeader,
  ListItem,
  ViewContext,
} from "shared/ui"
import {
  useMediaQuery,
  useTheme,
  Button,
  LinearProgress,
} from "@material-ui/core"
import {
  Menu as MenuIcon,
  MenuOpen as MenuHideIcon,
} from '@material-ui/icons'

function MenuViewController(props) {
  const { title, menuItems, selectedMenuIndex = -1, defaultMenuStyle = "visible", typeMenu, spaceSection = false } = props
  const [activePane, setActivePane] = useState(1)
  const [isLoading, setLoading] = useState(false)
  const [menuStyle, setMenuStyle] = useState(defaultMenuStyle)

  const classes = useStyles()
  const theme = useTheme()
  const smDown = useMediaQuery(theme.breakpoints.down("sm"))

  const viewContext = useContext(ViewContext)

  const renderDesktop = () => {
    if (menuStyle === "visible") {
      return (
        <SplitViewPane pane="side" width="small" className={classes.menuPane} fixedPosition={true}>
          <ListView showPageMenu={false} showTitle={false}>
            {menuItems.map((item, index) => item.type === "header" ?
              <ListHeader>{item.title}</ListHeader>
              :
              <ListItem
                variant="menuitem"
                primary={item.title}
                secondary={item.count > 0 && item.count}
                linkTo={item.linkTo}
                icon={item.icon}
                selected={selectedMenuIndex === index}
              />
            )}
          </ListView>
        </SplitViewPane>
      )
    } else if (typeMenu === 'onlyIcon') {
      let sectionTitleCurrent = ''

      return (
        <SplitViewPane pane="side" width="icon" className={classes.menuPane} fixedPosition={true}>
          <ListView showPageMenu={false} showTitle={false} variant='onlyIcon'>
            {menuItems.map((item, index) => {
              if(spaceSection){
                if(item.type == "header" && sectionTitleCurrent == ''){
                  sectionTitleCurrent = item.type
                } 

                if(item.type !== "header") {
                  return <ListItem
                          variant="menuitem"
                          linkTo={item.linkTo}
                          icon={item.icon}
                          iconSize="small"
                          selected={selectedMenuIndex === index}
                        />
                } else {
                  if(sectionTitleCurrent !== ''){
                    return <div style={{marginTop: '20px'}}></div>
                  }
                }
              } else {
                return item.type !== "header" &&
                  <ListItem
                    variant="menuitem"
                    linkTo={item.linkTo}
                    icon={item.icon}
                    iconSize="small"
                    selected={selectedMenuIndex === index}
                  />
              }
            })}
          </ListView>
        </SplitViewPane>
      )
    } else return null
  }

  return (
    <div className={classes.background}>
      <div className={classes.linearProgress}
        style={isLoading ? {} : { display: 'none' }} >
        <LinearProgress color="secondary" />
      </div>
      <ViewContext.Provider value={{
        ...viewContext,
        setLoading
      }}>
        <SplitView
          activePane={selectedMenuIndex === -1 ? 0 : activePane}
          onSetActivePane={setActivePane}
          fixedPosition={true}
        >
          {smDown ?
            <SplitViewPane pane="side" width="small" className={classes.menuPane} fixedPosition={true}>
              <ListView showPageMenu={false} showTitle={false}>
                {menuItems.map((item, index) => item.type === "header" ?
                  <ListHeader>{item.title}</ListHeader>
                  :
                  <ListItem
                    variant="menuitem"
                    primary={item.title}
                    secondary={item.count > 0 && item.count}
                    linkTo={item.linkTo}
                    onClick={() => setActivePane(1)}
                    icon={item.icon}
                    selected={selectedMenuIndex === index}
                  />
                )}
              </ListView>
            </SplitViewPane> :
            renderDesktop()
          }

          <SplitViewPane pane="content" className={classes.contentPane} fixedPosition={true}>
            {smDown ?
              <ViewContext.Provider value={{
                ...viewContext,
                breadcrumbs: [
                  {
                    icon: MenuIcon,
                    insetLeftMargin: true,
                    onClick: () => setActivePane(0)
                  }
                ]
              }}>
                {props.children}
              </ViewContext.Provider>
              :
              <ViewContext.Provider value={{
                ...viewContext,
                breadcrumbs: [
                  {
                    icon: menuStyle === "hidden" ? MenuIcon : MenuHideIcon,
                    insetLeftMargin: true,
                    onClick: () => setMenuStyle(menuStyle === "hidden" ? "visible" : "hidden")
                  }
                ]
              }}>
                {props.children}
              </ViewContext.Provider>
            }
          </SplitViewPane>
        </SplitView>
      </ViewContext.Provider>
    </div>
  )
}

export default MenuViewController