export const CONVERSATION_ACTION_GET_STATS = 'CONVERSATION_ACTION_GET_STATS'
export const CONVERSATION_SET_FETCHING_STATS = 'CONVERSATION_SET_FETCHING_STATS'
export const CONVERSATION_SET_STATS = 'CONVERSATION_SET_STATS'
export const CONVERSATION_ACTION_WS_CONNECT = 'CONVERSATION_ACTION_WS_CONNECT'
export const CONVERSATION_ACTION_WS_DISCONNECT = 'CONVERSATION_ACTION_WS_DISCONNECT'
export const CONVERSATION_ACTION_WS_SEND = 'CONVERSATION_ACTION_WS_SEND'
export const CONVERSATION_SET_WS_CONNECTING = 'CONVERSATION_SET_WS_CONNECTING'
export const CONVERSATION_SET_WS_CONNECTED = 'CONVERSATION_SET_WS_CONNECTED'
export const CONVERSATION_SET_WS_DISCONNECTED = 'CONVERSATION_SET_WS_DISCONNECTED'
export const CONVERSATION_ON_WS_NEW_MESSAGE = 'CONVERSATION_ON_WS_NEW_MESSAGE'
export const CONVERSATION_ON_WS_CONVERSATION_CHANGE = 'CONVERSATION_ON_WS_CONVERSATION_CHANGE'
export const CONVERSATION_ON_WS_CONVERSATION_MESSAGES_CHANGE = 'CONVERSATION_ON_WS_CONVERSATION_MESSAGES_CHANGE'