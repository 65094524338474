import View from './View'
import List from './List'
import useStyles from './styles'

export const ListView = ({ children, ...props }) => {    
  const classes = useStyles()

  return (
    <View {...props}>
      <div className={classes.listViewInset}>
        <List>
          { children }
        </List>
      </div>
    </View>
  )
}

export default ListView