import React, { useState, useEffect } from 'react'
import { withResizeDetector } from 'react-resize-detector'
import { FileInput, Button, Text } from '.'
import { Box, LinearProgress, useTheme } from "@material-ui/core"
import { Description } from "@material-ui/icons"
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import useStyles from './styles'

function FileListInputInner(props) {
  const {
    value,
    width,
    height,
    onChange,
    onUploadImage,
    onCancelUpload = null,
    name = 'attachments',
    required = false,
    readOnly = false,
    style = {},
    allowReorder = false,
    multipleValue = true
  } = props
  const [isUploading, setIsUploading] = useState(false)
  const [uploadPercentage, setUploadPercentage] = useState(0)
  const classes = useStyles()
  const theme = useTheme()

  /** define condition whether list can be reordered or not */
  const canReorder = allowReorder && !readOnly

  /**
   * @function upload - file uploader function
   * @param {File} file - file to be uploaded
   */
  const upload = (file) => {
    setIsUploading(true)
    onUploadImage(
      file,
      name,
      () => setIsUploading(false),
      (e) => {
        setUploadPercentage(Math.round((100 * e?.loaded) / e?.total))
      },
      () => setUploadPercentage(0)
    )
  }

  /**
   * 
   * @param {Array} list - original item array
   * @param {Number} startIndex - source/original index
   * @param {Number} endIndex - destination/drop index
   * @returns {Array} - reordered array result
   */
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
  }

  /**
   * @function onDragEnd - invoked on finished dragging item
   * @param {Object} result - drag end result with source & destination
   * @returns - returns null if dropped outside list
   */
  const onDragEnd = (result) => {
    /** return if dropped outside the list */
    if (!result.destination) return
    const reorderedItems = reorder(value, result?.source?.index, result?.destination?.index)
    onChange(reorderedItems)
  }

  return (
    <div className={classes.imageListInput} style={style}>
      <DragDropContext onDragEnd={onDragEnd}>
        <div
          className={classes.imageListInputContent}
          style={{
            // position: "absolute",
            width: `${width}px`,
            height: `${height}px`,
            overflowX: "auto",
            overflowY: "hidden",
          }}
        >
          <Droppable droppableId={`file-list-droppable-${name}`} isDropDisabled={!canReorder} direction="horizontal">
            {providedDrop => (
              <div style={{ display: 'flex' }} ref={providedDrop.innerRef} {...providedDrop.droppableProps}>
                {value && value.map((image, index) =>
                  <Draggable draggableId={`filelist-draggable-${name}-${index}`} index={index} key={index} isDragDisabled={!canReorder}>
                    {providedDrag => (
                      <div ref={providedDrag.innerRef} {...providedDrag.draggableProps} {...providedDrag.dragHandleProps}>
                        <FileInput value={image}
                          allowDelete={!readOnly}
                          onDelete={() => {
                            let list = [...value]
                            list.splice(index, 1)
                            onChange(list)
                          }}
                        />
                      </div>
                    )}
                  </Draggable>
                ).reduce((p, n) => p ?
                  [...p, <div className={classes.imageListInputSpacing} />, n] : [n]
                  , null
                )}
                {providedDrop.placeholder}
              </div>
            )}
          </Droppable>
          {value &&
            <div className={classes.imageListInputSpacing} />
          }
          {isUploading &&
            <>
              <div className={classes.imageInput}>
                <div className={classes.imageInputPlaceholder}>
                  {onCancelUpload &&
                    <div className={classes.cancelUpload}>
                      <Button
                        kind="cancel"
                        variant="icon"
                        size="small"
                        onClick={() => onCancelUpload(() => setIsUploading(false))}
                      />
                    </div>
                  }
                  <Box display='flex' flexDirection='column' alignItems='center'>
                    <Description style={{ marginBottom: theme.spacing(1) }} />
                    <Text variant="caption" color='textMuted'>
                      {`${uploadPercentage}%`}
                    </Text>
                    <div className={classes.uploadProgress}>
                      <LinearProgress />
                    </div>
                  </Box>
                </div>
              </div>
              <div className={classes.imageListInputSpacing} />
            </>
          }

          { !readOnly && 
            (
              multipleValue ||
              (
                (!multipleValue && !value) ||
                (!multipleValue && value.length == 0))
            ) &&
            <>
              <FileInput id={name} onUploadImage={upload} />
              {required &&
                <span style={{ marginLeft: '0.5rem', color: 'red' }}>*</span>
              }
            </>
          }
        </div>
      </DragDropContext>
    </div>
  )
}

export const FileListInput = withResizeDetector(FileListInputInner)

export default FileListInput