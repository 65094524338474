import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'
import useStyles from './styles'
import {
  FormView,
  Text,
  ViewContext,
  SplitView,
  SplitViewPane,
  Tabs,
  Tab,
  Button,
  Skeleton,
} from "shared/ui"
import { OpenInNewOutlined } from '@material-ui/icons'

function DetailViewController(props) {
  const { isNew, isFetching, lastFetched, data } = props
  const { isSaving, headerRef = null } = props
  const { renderContent, activePane = 0 } = props
  const viewContext = useContext(ViewContext)
  const classes = useStyles()

  // Editing feature
  const { tabs, selectedTab, onSelectedTabChange } = props
  const { sidePanels } = props
  const { sidePanelsScroll } = props
  const { sidePanelTabs, selectedSidePanelTab, onSelectedSidePanelTabChange } = props
  const { editMode = "none", changedData, onDataChange, errorFields, onSave, onReset } = props
  const hasChanged = Boolean(isNew || (changedData && Object.keys(changedData).length > 0))
  const { primaryButton, secondaryButtons, sidebarButtons, showPrimaryButtonLast=false } = props
  const { classNameContentPane } = props

  // Infinite scroll props
  const { fixedPosition = false, onScroll = () => null } = props

  // View header
  const { useHeader = true, hideTitle } = props

  useEffect(() => {
    viewContext.setLoading(isFetching)
    return () => {
      viewContext.setLoading(false)
    }
  }, [isFetching])

  let actionButtons = []
  if (editMode === "always" || (editMode === "auto" && hasChanged)) {
    // Add Save and Cancel button
    actionButtons.push({
      color: "primary",
      variant: "contained",
      kind: "save",
      onClick: onSave,
      label: "Save",
      loading: isSaving,
      disabled: !hasChanged,
    })
    actionButtons.push({
      kind: "cancel",
      onClick: onReset,
      label: "Cancel",
    })
  }
  else {
    if (primaryButton) {
      actionButtons.push({
        variant: "contained",
        color: "primary",
        ...primaryButton,
      })
    }

    if (secondaryButtons)
      actionButtons = showPrimaryButtonLast ? [...secondaryButtons, ...actionButtons] : [...actionButtons, ...secondaryButtons]
  }

  if(viewContext?.isSidebarView){
    if(sidebarButtons){
      actionButtons = [...actionButtons, ...sidebarButtons]
    }

    actionButtons.push({ kind: "close", variant: "icon", onClick: () => viewContext?.onClose && viewContext.onClose() })
  }

  if (actionButtons.length > 0 && actionButtons[actionButtons.length - 1].variant !== "contained")
    actionButtons[actionButtons.length - 1].insetRightMargin = true

  function renderSidePanelTabs() {
    if (!sidePanelTabs)
      return

    return (
      <div className={classes.detailViewSidePanelTabs}>
        {sidePanelTabs.map((t, index) =>
          <Button {...t}
            color={index === selectedSidePanelTab ? "primary" : ""}
            insetRightMargin={index === sidePanelTabs.length - 1}
            onClick={() => onSelectedSidePanelTabChange(index)}
          />
        )}
      </div>
    )
  }

  return (
    <FormView title={hideTitle ? null : (props.title || <Skeleton />)}
      headerProps={{
        sideContent: renderSidePanelTabs(),
        ...({...props.headerProps, scrollXHeader: viewContext?.isSidebarView} || {}),
      }}
      extraBreadcrumbsContent={props.extraBreadcrumbsContent}
      footerProps={props.footerProps}
      actionButtons={actionButtons}
      initialData={isNew ? {} : lastFetched && data || {}}
      changedData={changedData}
      onDataChange={onDataChange}
      errorFields={errorFields}
      variant={props.viewVariant}
      {...{ fixedPosition, onScroll, headerRef, useHeader }}
    >
      {tabs &&
        <div className={classes.detailViewTabs}>
          <Tabs value={selectedTab} borderBottom={true}>
            {tabs.map((t, index) =>
              <Tab
                value={t.value || index}
                label={t.label}
                count={t.count}
                onClick={() => onSelectedTabChange(index)}
              />
            )}
          </Tabs>
        </div>
      }
      <SplitView divider="space" activePane={activePane} classNameContentPane={classNameContentPane}>
        <SplitViewPane pane="content">
          {lastFetched && renderContent && renderContent()}
          {props.children}
        </SplitViewPane>

        {sidePanels &&
          <SplitViewPane pane="side" width="medium" fixedPosition="fixed" SplitViewPaneScroll={sidePanelsScroll}>
            {false && sidePanelTabs &&
              <>
                <div style={{ height: 4 }} />
                {renderSidePanelTabs()}
              </>
            }
            {props.viewVariant === "dimmed" &&
              <div style={{ height: 16 }} />
            }
            {sidePanels}
          </SplitViewPane>
        }
      </SplitView>

    </FormView>
  )
}

export default DetailViewController