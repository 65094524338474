import React from 'react'
import {
  Accordion  
} from '.'
import useStyles from './styles'

export function AccordionGroup({children}) {
  const classes = useStyles()
  const [expandedPanel, setExpandedPanel] = React.useState(-1);

  const handleChange = (panelIndex) => (event, isExpanded) => {
    setExpandedPanel(isExpanded ? panelIndex : -1)
  }

  let index = 0
  const accordions = React.Children.map(children, e => {
    if (React.isValidElement(e) && e.type == Accordion) {
      const res = React.cloneElement(e, {
          expanded: expandedPanel == index,
          onChange: handleChange(index),
        })
      index += 1
      return res
    }
    else
      return e
  })

  return (
    <div className={classes.accordionGroup}>
      {accordions}
    </div>
  )
}