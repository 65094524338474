import React, { useState, useEffect, useContext } from 'react'
import { Chip, useMediaQuery, useTheme, Box as MuiBox } from '@material-ui/core'
import clsx from 'clsx'
import Image from './Image'
import formatValue from "../utils/formatValue"
import Skeleton from '@material-ui/lab/Skeleton'
import {
  Box,
  CircularProgress,
  Table as MuiTable,
  TableBody as MuiTableBody,
  TableCell as MuiTableCell,
  TableHead as MuiTableHead,
  TableRow as MuiTableRow,
  Checkbox,
} from '@material-ui/core'
import useStyles from './styles'
import RichText from './RichText'
import { useAppContext } from 'App'
import { getStyleProgressStatus } from 'shared/utils'
import Text from './Text'
import moment from 'moment'
import FileListView from './FileListView'

const TableCell = (props) => {
  const classes = useStyles()
  const theme = useTheme()
  // const {column, header, style, value, secondaryValue, onClick} = props
  const { column, header, getValue, onClick, stickyCell = false, isSelected } = props
  let align = column.alignment || 'left'
  if (column.type === 'number' || column.type == 'currency' || column.type == 'decimal')
    align = 'right'
  let customStyle = {...column.valueStyle} || {}

  customStyle["padding"] = theme.spacing(2)

  if (column.wordWrap === false || column.type == 'currency')
    customStyle['white-space'] = 'nowrap'

  if (column.width) {
    customStyle['width'] = column.width
    customStyle['flexShrink'] = false
  }

  if(stickyCell) {
    customStyle['zIndex'] = header ? 1 : 0
  }

  if (header)
    return (
      <MuiTableCell className={clsx(classes.tableCellHeader, stickyCell && classes.tableCellSticky)} key={column.key} align={align} style={customStyle}
      >
        {column.name}
      </MuiTableCell>
    )

  const media = getValue("media")
  const primary = getValue("primary")
  const secondary = getValue("secondary")
  const third = getValue("third")

  return (
    <MuiTableCell 
      className={clsx(classes.tableCell, stickyCell && classes.tableCellSticky, isSelected && classes.tableCellItemSelected)} 
      key={column.key} 
      align={align} 
      style={customStyle}
      onClick={onClick}
    >
      {
        isSelected &&
        <div className={classes.wrapItemSelected}>
          <div className={classes.itemSelected}/>
        </div>
      }
      {media ?
        <Box display='flex' alignItems='flex-start'>
          {media}
          <Box ml={2} flexGrow={1}>
            <div className={classes.primaryText}>
              {primary}
            </div>
            {secondary &&
              <>
                <Box mt={.2} />
                <div className={classes.secondaryText}>
                  {secondary}
                </div>
              </>
            }
            {third &&
              <>
                <Box mt={.2} />
                <div className={classes.thirdText}>
                  {third}
                </div>
              </>
            }
          </Box>
        </Box>
        :
        <>
          <div className={classes.primaryText}>
            {primary}
          </div>
          {secondary &&
            <>
              <Box mt={.2} />
              <div className={classes.secondaryText}>
                {secondary}
              </div>
            </>
          }
          {third &&
            <>
              <Box mt={.2} />
              <div className={classes.thirdText}>
                {third}
              </div>
            </>
          }
        </>
      }
    </MuiTableCell>
  )
}

export const Table = ({
  itemListRef, columns, rows, showCheckBox, showIndicatorSelected = false, skeleton, isFetching, hasMore, onRowClick, onRowSelect, isRowSelected, showColumnHeader = true, disabled = false, stickyColumn = false, stickyColumnIndex = 0 ,columnHeaderStyle = {}, ...props
}) => {

  const classes = useStyles()
  const theme = useTheme()
  const appContext = useAppContext()

  const getUserName = (user) => {
    if(!user) return ''
    
    let name = ''
    if (user) {
      if (user?.first_name || user?.last_name) {
        name = `${user?.first_name || ""} ${user?.last_name || ""}`.trim()
      }
    }

    if(!name){
      name = `Contact ${user?.uid % 9999}`
    }

    return name
  }

  const getValue = (row, column, fieldKey) => {
    const field = column[fieldKey] || {}

    if (skeleton) {
      if (field.render || field.key) {
        if (fieldKey == "media")
          return (
            <Box style={{
              width: field.width || '10rem',
            }}>
              <Skeleton
                variant="rect"
                width={field.width || '10rem'}
                height={field.width || '10rem'}
              />
            </Box>
          )

        return <Skeleton />
      }
      return null
    }


    if (field.render) {
      if (field.variant == 'title')
        return (
          <span className={classes.titleText}>
            {field.render(row, column)}
          </span>
        )

      return field.render(row, column)
    }

    if (props.getCellValue)
      return props.getCellValue(row, column, fieldKey)

    let value
    if (field.getValue)
      value = field.getValue(row, column)
    else if(field.field_type == 'contact')
      value = getUserName(row[field.key])
    else if(field.field_type == 'company')
      value = row[field.key]?.name
    else if(field?.field_type == 'company_contact')
      value = `${getUserName(row[field.key]?.contact)} ${row[field.key]?.company ? `from ${row[field.key]?.company?.name}` : ''}`
    else if(field.field_type == 'option'){
      if (field?.multiple_value) {
        value = row[field.key]?.map(field => <Chip label={field.name} style={{margin: '3px 5px 3px 0'}}/>)
      } else value = row[field.key]?.name
    }
    else if(field.field_type == 'custom' && field?.key == 'esb_outlet'){
      value = row?.[field.key]?.name || row?.[field.key]?.title || row?.[field.key]?.value
    }
    else if(field.field_type == 'user'){
      if (row[field.key]) {
        if (row[field.key].uid == appContext.userInfo?.uid)
          value = "Me"
        else
          value = `${row[field.key].first_name || ''} ${row[field.key].last_name || ''}`
      }else {
        // Assigned to all agent
        value = "Unassigned"
      }
    }
    else if(field.field_type == 'status'){
      value = (
        <div style={{ display: 'flex' }}>
          <MuiBox>
            <Text
              variant="body2"
              noWrap={true}
              textCustomProps={getStyleProgressStatus(row[field.key])}
            >
              {row[field.key]?.name}
            </Text>
          </MuiBox>
        </div>
      )
    }
    else if(field.field_type == 'float')
      value = row[field.key] ? new Intl.NumberFormat('ID').format(row[field.key]) : ''
    else if(
      field.field_type == 'date' ||
      (moment(row[field.key], moment.ISO_8601, true).isValid() && !field.format) // check value is date without format
    )
      value = formatValue(row[field.key], 'datetime|DD MMM yyy')
    else if (field?.field_type == 'media')
      value = row[field.key] ? (
        <FileListView value={row[field.key]} useSmallThumbs disableBorder />
      ) : <></>
    else
      value = formatValue(row[field.key], field.format)

    if (value === null || value === undefined || value === "")
      return null
    else if (typeof value === "function")
      return value
    else if (fieldKey == "media")
      return (
        <Box style={{
          width: field.width || '10rem',
        }}
        >
          <Image src={value} aspectRatio={column.field} />
        </Box>
      )
    /*
    else if (column.type == 'button')
      valueComponent = (
        <Box display='flex' flexDirection='row'>
          {value}
        </Box>
      )
    */
    else if (field.variant == 'title')
      return (
        <span className={classes.titleText}>
          {value}
        </span>
      )
    else
      return value
  }

  const getCellValue = (row, column, rowIndex, columnIndex) => {
    if (column.primary)
      return getCellValue(row, column.primary, rowIndex, columnIndex)

    if (column.render)
      return column.render(row, column, rowIndex, columnIndex)
    if (props.getCellValue)
      return props.getCellValue(row, column, rowIndex, columnIndex)

    return formatValue(row[column.key], column.format)
  }

  const getCellSecondaryValue = (row, column, rowIndex, columnIndex) => {
    if (column.secondary)
      return getCellValue(row, column.secondary, rowIndex, columnIndex)

    if (props.getCellSecondaryValue)
      return props.getCellSecondaryValue(row, column)
    return null
  }

  const getCellStyle = (row, column, rowIndex, columnIndex) => {
    if (props.getCellStyle)
      return props.getCellStyle(row, column, rowIndex, columnIndex)
    return null
  }

  return (
    <div className={clsx(classes.table)}>
      <MuiTable stickyHeader {...props}>
        {showColumnHeader &&
          <MuiTableHead>
            <MuiTableRow>
              {showCheckBox &&
                <MuiTableCell className={classes.tableCellHeaderCheckBox} style={columnHeaderStyle}>
                  <Checkbox
                    indeterminate={false}
                    checked={false}
                    disabled
                  />
                </MuiTableCell>
              }
              {columns.map(c => ({ ...c, valueStyle: { ...columnHeaderStyle } })).map((column, columnIndex) => (
                <TableCell header={true} stickyCell={stickyColumn && stickyColumnIndex == columnIndex} theme={theme} classes={classes} column={column} />
              ))}
            </MuiTableRow>
          </MuiTableHead>
        }
        <MuiTableBody>
          {skeleton
            ? [...Array(skeleton)].map((_, rowIndex) => (
              <MuiTableRow key={rowIndex}>
                {columns.map((column, columnIndex) => (
                  <TableCell theme={theme} classes={classes}
                    column={column}
                    getValue={(fieldKey) => getValue(undefined, column, fieldKey)}
                  />
                ))}
              </MuiTableRow>
            ))
            : rows && rows.map((row, rowIndex) => {
              return (
                <MuiTableRow hover selected={!showCheckBox && isRowSelected && isRowSelected(row, rowIndex)} tabIndex={-1} key={row.code} ref={ref => {if(itemListRef) itemListRef.current[rowIndex] = ref}}>
                  {showCheckBox &&
                    <MuiTableCell className={classes.tableCellCheckBox}
                      onClick={() => onRowSelect && onRowSelect(row, rowIndex)}
                    >
                      <Checkbox
                        checked={isRowSelected && isRowSelected(row, rowIndex)}
                        onChange={(event) => onRowSelect && onRowSelect(row, rowIndex)}
                      />
                    </MuiTableCell>
                  }
                  {columns.map((column, columnIndex) => (
                    <TableCell theme={theme} classes={classes}
                      stickyCell={stickyColumn && stickyColumnIndex == columnIndex}
                      column={column}
                      getValue={(fieldKey) => getValue(row, column, fieldKey)}
                      onClick={() => onRowClick && onRowClick(row, rowIndex)}
                    />
                  ))}
                </MuiTableRow>
              );
            })
          }
        </MuiTableBody>
      </MuiTable>

      {isFetching &&
        <Box display='flex' justifyContent='center' style={{ padding: theme.spacing(2) }}>
          <CircularProgress size='1.5rem' disableShrink={true} />
        </Box>
      }
    </div>
  )
}

export default Table