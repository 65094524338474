import React, { useState, useCallback, useEffect } from 'react'
import { ContentState, EditorState, convertFromHTML, convertToRaw } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import draftToHtml from 'draftjs-to-html'
import { debounce } from 'lodash'
import useStyles from './styles'
import clsx from 'clsx'
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

export const TextEditor = ({ defaultValue, onFieldChange, placeholder = '', bodyNotEmpty, childFunc, wrapperClassName, editorClassName }) => {
  const [editorState, setEditorState] = useState(defaultValue ? 
    EditorState.createWithContent(
      ContentState.createFromBlockArray(
        convertFromHTML(defaultValue)
      )
    ) : EditorState.createEmpty())
  const classes = useStyles()

  const onEditorStateChange = newState => setEditorState(newState)

  const convertValueOnChange = useCallback(debounce((v) => {
    if (onFieldChange) {
      const converted = draftToHtml(convertToRaw(v?.getCurrentContent()))
      onFieldChange(converted)
    }
  }, 200), [])

  const clearText = () => {
    setEditorState(EditorState.createEmpty())
  }

  useEffect(() => {
    convertValueOnChange(editorState)
  }, [editorState])

  useEffect(() => {
    childFunc.current = clearText
  }, [])
  

  return (
    <Editor
      editorState={editorState}
      wrapperClassName={clsx("demo-wrapper", wrapperClassName)}
      editorClassName={clsx(classes.editorField, bodyNotEmpty ? classes.editorNotEmptyPlaceholder : '', editorClassName )}
      toolbarClassName={classes.editorToolbar}
      onEditorStateChange={onEditorStateChange}
      placeholder={placeholder}
      toolbar={{
        options: ['inline', 'link', 'list', 'blockType'],
        inline: {
          inDropdown: false,
          options: ['bold', 'italic', 'strikethrough'],
        },
        list: {
          inDropdown: false,
          options: ['unordered', 'ordered']
        },
        blockType: {
          inDropdown: false,
          options: ['Blockquote']
        }
      }}
    />
  )
}