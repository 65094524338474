import React, { useState, useEffect, useContext } from "react"
import { useMediaQuery, useTheme } from "@material-ui/core"
import clsx from "clsx"
import formatValue from "../utils/formatValue"
import {
  ListItem,
  Text,
  Image,
} from "."
import {
  Button as MuiButton,
  Box as MuiBox,
  Badge as MuiBadge,
} from "@material-ui/core"
import useStyles from './styles'

export const CardMedia = ({media, width, aspectRatio, selected}) => {
  return (
    <div style={{
        width: width || '10rem',
        flexShrink: 0
      }}
      >
      <Image src={media} aspectRatio={aspectRatio} />
    </div>
  )
}

export const CardTitle = ({primary, textPrimaryColor, secondary, maxLine=1, selected, textCustomPropsSecondary={}}) => {
  return (
    <MuiBox display='flex' alignItems='baseline'>
      <MuiBox flexGrow={1}>
        <Text 
          style={{ fontWeight: 700 }} 
          color={selected ? 
            "textPrimaryContrast" : 
            typeof textPrimaryColor !== 'undefined' ? textPrimaryColor : "textPrimary"
          }
          maxLine={maxLine}>
          {primary}
        </Text>
      </MuiBox>
      {secondary &&
        <MuiBox>
          <Text
            variant="body2"
            color={selected ? "textSecondaryContrast" : "textSecondary"} 
            noWrap={true}
            textCustomProps={textCustomPropsSecondary}
          >
            {secondary}
          </Text>          
        </MuiBox>
      }
    </MuiBox>
  )
}

export const CardSubtitle = ({primary, textPrimaryColor, secondary, maxLine=4, selected}) => {
  return (
    <MuiBox display='flex' alignItems='center'>
      <MuiBox flexGrow={1}>
        <Text
          variant="body2"
          color={selected ? 
            "textPrimaryContrast" : 
            typeof textPrimaryColor !== 'undefined' ? textPrimaryColor : "textPrimary"
          }
          maxLine={maxLine}
        >
          {primary}
        </Text>
      </MuiBox>
      {secondary &&
        <MuiBox>
          <Text
            variant="body2"
            color={selected ? "textSecondaryContrast" : "textSecondary"} 
            noWrap={true}
          >
            {secondary}
          </Text>          
        </MuiBox>
      }
    </MuiBox>
  )
}

export const CardBody = ({rows, selected}) => {
  return (
    <MuiBox>
      {rows.map(row =>
        <Text
          variant="body2"
          maxLine={4}
          color={selected ? "textSecondaryContrast" : "textSecondary"} 
        >
          {row}
        </Text>
      )}
    </MuiBox>
  )
}

export const CardFooter = ({primary, textPrimaryColor, secondary, maxLine=4, selected}) => {
  const classes = useStyles()
  
  return (
    <>
      <div className={classes.separatorFooter} />
      <MuiBox display='flex' alignItems='center'>
        <MuiBox flexGrow={1}>
          <Text
            variant="body2"
            color={selected ? 
              "textPrimaryContrast" : 
              typeof textPrimaryColor !== 'undefined' ? textPrimaryColor : "textPrimary"
            }
            maxLine={maxLine}
          >
            {primary}
          </Text>
        </MuiBox>
        {secondary &&
          <MuiBox>
            <Text
              variant="body2"
              color={selected ? "textSecondaryContrast" : "textSecondary"} 
              noWrap={true}
            >
              {secondary}
            </Text>          
          </MuiBox>
        }
      </MuiBox>
    </>
  )
}

export const Card = (props) => {    
  const classes = useStyles()
  const {selected, onClick, children, ...listItemProps} = props
  
  let media = []
  let contents = []
  React.Children.forEach(children, el => {    
    if (React.isValidElement(el) && el.type == CardMedia)
      media.push(el)
    else
      contents.push(el)
  })


  return (
    <ListItem onClick={onClick}
      component="li"
      selected={selected}
      primary={media.length > 0 && contents.length > 0 ?
        <MuiBox display='flex'>
          {media}
          <MuiBox ml={media.length > 0 ? 1 : 0} flexGrow={1}>
            {contents}
          </MuiBox>
        </MuiBox>
        :
        (media.length > 0 ? media : contents)
      }
      {...listItemProps}
      />
  )
}

// export default React.memo(Card,
//   (prevProps, nextProps) => {
//     return prevProps.selected === nextProps.selected
//   }
// )

export default Card