import { spawn } from 'redux-saga/effects'
//import { spawn, takeLatest } from 'redux-saga/effects'
//import { INITIAL_ACTION_INITIALIZE } from '../types'
import { authSaga } from './authSaga'
import { queueSaga } from './queueSaga'
import { storeSaga } from './storeSaga'
import { projectSaga } from './projectSaga'
//import initialize from './initialize'

export default function* rootSaga() {
  yield spawn(authSaga)
  yield spawn(queueSaga)
  yield spawn(storeSaga)
  yield spawn(projectSaga)
  //  yield takeLatest(INITIAL_ACTION_INITIALIZE, initialize)
}
