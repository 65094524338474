import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  // View, Panel and Section
  view: {
    width: '100%',
    // backgroundColor: '#fff'
    position: 'relative',
  },
  viewContent: {
    padding: theme.spacing(3.5),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
    paddingTop: 0,
    paddingBottom: 0,
    position: "relative",
  },
  viewContentSideBar: {
    padding: theme.spacing(2),
  },
  viewContentOnlyIcon: {
    padding: theme.spacing(2),
  },
  viewContentFlushed: {
    padding: '0 !important'
  },
  viewContentDimmed: {
    backgroundColor: '#f2f2f2',
  },
  viewHeader: {
    paddingTop: theme.spacing(3.5),
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(.5),
    },
  },
  viewHeaderGutter: {
    height: theme.spacing(1),
  },
  viewFooter: {
    paddingBottom: theme.spacing(3.5),
  },
  viewFooterGutter: {
    height: theme.spacing(2),
  },
  viewFooterMobile: {
    paddingTop: 0
  },
  viewPageMenu: {
    display: 'flex',
    alignItems: 'center',
    height: 36,
    marginTop: theme.spacing(-1.5),
    paddingBottom: theme.spacing(.5),
  },
  viewPageBreadcrumbs: {
    display: 'flex',
    alignItems: 'center',
  },
  viewPageBreadcrumbsExtraContent: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
  },
  viewPageActionButtons: {
    display: 'flex',
    alignItems: 'center'
  },
  viewPageActionButtonsSidebar: {
    overflowX: 'auto'
  },
  viewPageTitle: {
  },
  actionButtonPadding: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(4),
  },
  actionWithBack:{
    justifyContent: 'space-between',
    width: '100%',
    paddingRight: '4px',
    paddingLeft: '12px'
  },
  listViewInset: {
    marginLeft: theme.spacing(-2),
    marginRight: -(theme.spacing(2)),
  },
  panel: {
    width: '100%',
  },
  panelShadow: {
    boxShadow: theme.shadows[2],
    borderRadius: theme.shape.borderRadius,
    overflow: "hidden",
  },
  panelTitleText: {
    fontSize: theme.typography.body1.fontSize,
    // fontSize: "1.05rem",
    fontWeight: 800,
  },
  panelGutterBottom: {
    marginBottom: theme.spacing(2),
  },
  panelExpansionContent: {
    // padding: `${theme.spacing(2)}px ${theme.spacing(1)}px`, 
    padding: theme.spacing(2),
    marginTop: '-18px',
    "& div": {
      padding: "0px"
    },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },
  panelList: {
    margin: theme.spacing(-2),
  },
  panelFlushed: {
    padding: '0px !important'
  },
  panelDetailFlushed: {
    padding: '16px 0 !important'
  },
  panelBorder: {
    borderBottom: '1px solid #ccc'
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
    width: '100%',
  },
  headerMainContent: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  headerCenteredContent: {
    flexDirection: 'column',
    alignItems: 'center'
  },
  headerLeftedContent: {
    width: '100%'
  },
  headerTitle: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'stretch',
    flexDirection: 'column',
  },
  headerTitleWithAvatar: {
    marginTop: theme.spacing(0),
  },
  centeredTitle: {
    textAlign: 'center'
  },
  leftedTitle: {
    textAlign: 'left',
    padding: '0 12px'
  },
  headerSubtitle: {
    marginTop: '0rem',
  },
  headerAvatarWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  centeredAvatar: {
    marginRight: 0,
  },
  headerSideContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    width: '100%',
  },
  headerBorderBottom: {
    borderBottom: '1px solid #e8e8e8'
  },
  section: {
    position: 'relative'
  },
  sectionHeader: {
    // backgroundColor: '#efefef',
    // marginLeft: -(theme.spacing(1.5)),
    // marginRight: -(theme.spacing(1.5)),
    // padding: theme.spacing(1.5)
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
  },
  subsectionHeader: {
  },
  sectionTitleText: {
    // fontSize: theme.typography.h6.fontSize,
    fontSize: "1.05rem",
    fontWeight: 800,
  },
  subsectionTitleText: {
    fontSize: theme.typography.body2.fontSize,
    fontWeight: 600,
    color: '#777'
  },
  accordion: {

  },
  accordionExpanded: {
    // borderTop: '1px solid #eee',
    // borderBottom: '1px solid #eee',
    // border: '1px solid #eee',
    backgroundColor: '#fafafa',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  accordionSummary: {
    padding: 0,
  },
  accordionDetails: {
    padding: 0,
  },
  accordionSummaryFlex: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  accordionPrimaryText: {
    fontSize: theme.typography.body1.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    flexGrow: 1,
  },
  accordionSecondaryText: {
    marginLeft: theme.spacing(1),
    color: theme.palette.grey.muted,
  },
  accordionSwitch: {
    marginLeft: theme.spacing(1),
  },
  // SplitView 
  splitView: {
    width: '100%',
    display: 'flex',
    direction: 'row',
  },
  splitViewMobile: {
    width: '100%',
    position: 'relative',
  },
  splitViewSidePane: {
    minWidth: 270,
    width: '23%',
    position: 'relative',
    flexShrink: 0,
  },
  splitViewSmallSidePane: {
    minWidth: 200,
    width: '15%',
  },
  splitViewIconSidePane: {
    minWidth: 50,
    width: 50,
  },
  splitViewLargeSidePane: {
    minWidth: 320,
    width: '27%',
  },
  splitViewSidePaneCollapsed: {
    width: 60,
    minWidth: 60,
  },
  splitViewSidePaneContainer: {
    // position: 'fixed',
    // overflow: 'scroll',
    width: '100%',
    // backgroundColor: '#eaeaea'
  },
  splitViewPaneHidden: {
    display: 'none',
  },
  splitViewDividerLine: {
    width: 1,
    flexShrink: 0,
    backgroundColor: "#eee",
  },
  splitViewDividerSpace: {
    width: theme.spacing(3.5),
    flexShrink: 0,
  },
  splitViewMobilePane: {
    width: '100%',
  },
  splitViewContentPane: {
    flexGrow: 1,
    position: 'relative'
  },
  splitViewContentPaneContainer: {
  },
  splitViewPaneTitle: {
    fontSize: theme.typography.body2.fontSize,
    fontWeight: 700,
    color: '#888',
    marginBottom: theme.spacing(2),
  },
  // Filter Bar
  filterBar: {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
  },
  filterBarFieldSeparator: {
    // width: theme.spacing(1),
  },
  filterBarPrimaryFieldSeparator: {
    width: theme.spacing(1),
  },
  filterBarPrimaryRow: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      flexWrap: "wrap",
    },
  },
  filterBarSearchField: {
    flexGrow: 1,
    [theme.breakpoints.down('sm')]: {
      width: "100%",
    },
  },
  filterBarButtonField: {
    flexGrow: 1,
    [theme.breakpoints.down('md')]: {
      width: "90%",
    },
    [theme.breakpoints.down('sm')]: {
      width: "85%",
    },
  },
  filterBarPrimaryField: {
    // minWidth: 160,
  },
  filterBarSecondaryRow: {
    display: "flex",
    alignItems: "stretch",
    marginTop: theme.spacing(1),
    // overflowX: 'auto',
    // whiteSpace: 'nowrap',
    // [theme.breakpoints.up('md')]: {
    //   paddingBottom: theme.spacing(0.5),
    //   '&::-webkit-scrollbar': {
    //     width: theme.spacing(0.5),
    //     height: theme.spacing(0.5)
    //   },
    //   '&::-webkit-scrollbar-track': {
    //     borderRadius: theme.spacing(0.5),
    //     backgroundColor: '#f2f2f2'
    //   },
    //   '&::-webkit-scrollbar-thumb': {
    //     borderRadius: theme.spacing(0.5),
    //     backgroundColor: '#dcdcdc'
    //   },
    // },
    columnGap: theme.spacing(1),
    flexWrap: 'wrap',
    overflowX: 'hidden', // Must be set, otherwise on some viewport it will show scroll bar
    [theme.breakpoints.down('sm')]: {
      flexWrap: "nowrap",
      overflowX: 'auto',
    },
  },
  filterBarSecondaryField: {
    // marginRight: theme.spacing(1)
    // minWidth: 160,
    maxWidth: '100% !important',
  },
  filterBarAddedHeight: {
    marginTop: '2.2px'
  },
  filterBarWidthLimit: {
    minWidth: '100px !important',
    maxWidth: '100px !important'
  },
  filterButton:{
    backgroundColor: '#f2f2f2',
    height: '39px',
    minWidth: '40px',
    padding: '6px 10px'
  },
  secondaryFieldRoot : {
    maxWidth: '100% !important',
  },
  // Tabs
  tabs: {
    minHeight: 32,
  },
  tabsBorderBottom: {
    minHeight: 32,
    borderBottom: '1px solid #e8e8e8',
  },
  tab: {
    minHeight: 32,
    textTransform: 'none',
    minWidth: 0,
    margin: 0,
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
    marginRight: theme.spacing(2),
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.fontWeightRegular,
    color: theme.palette.grey.muted,
    '&:hover': {
    },
    '&$selected': {
    },
    '&:focus': {
    },
  },
  tabNoMargin: {
    minHeight: 32,
    textTransform: 'none',
    minWidth: 0,
    margin: 0,
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.fontWeightRegular,
    color: theme.palette.grey.muted,
    '&:hover': {
    },
    '&$selected': {
    },
    '&:focus': {
    },
  },
  tabSelected: {
    color: "black",
    // fontWeight: theme.typography.fontWeightBold,    
  },
  // Table
  table: {
  },
  tableFullBleed: {
    marginLeft: -(theme.spacing(2)),
    marginRight: -(theme.spacing(2)),
  },
  tableCellHeader: {
    verticalAlign: 'bottom',
    fontSize: theme.typography.body2.fontSize,
    // color: theme.palette.grey.muted,
    backgroundColor: "transparent",
    fontWeight: 800,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  tableCellSticky: {
    position: 'sticky',
    left: 0,
    background: 'white',
    // '&:hover': {
    //   backgroundColor: "transparent",
    // } 
  },
  tableCellItemSelected: {
    position: 'relative'
  },
  tableCell: {
    verticalAlign: 'top',
    cursor: 'pointer'
    // borderBottom: 'none'
  },
  tableCellCheckBox: {
    verticalAlign: 'top',
    // borderBottom: 'none',
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(0),
    width: 1, // This help make the column have fixed width
  },
  tableCellHeaderCheckBox: {
    verticalAlign: 'bottom',
    backgroundColor: "transparent",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(0),
    paddingBottom: theme.spacing(1),
  },
  // Board
  board: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    height: "100%",
    // backgroundColor: "red",
    overflowX: "auto",
    marginLeft: theme.spacing(-3),
    paddingLeft: theme.spacing(3),
    marginRight: theme.spacing(-3),
    paddingRight: theme.spacing(3),
  },
  boardColumn: {
    width: 280,
    flexShrink: 0,
    flexGrow: 0,
    backgroundColor: '#f2f2f2',
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
  },
  boardColumnHeader: {
    padding: theme.spacing(1),
    paddingTop: '3px',
    fontSize: theme.typography.body1.fontSize,
    fontWeight: 600,
  },
  boardColumnSummary: {
    padding: `0 ${theme.spacing(1)}px 0 ${theme.spacing(1)}px`,
    fontSize: theme.typography.body2.fontSize,
  },
  boardColumnFooter: {
    padding: theme.spacing(1),
    fontSize: theme.typography.body1.fontSize,
    fontWeight: 400,
  },
  boardColumnDivider: {
    width: theme.spacing(2),
    flexShrink: 0,
    flexGrow: 0,
  },
  boardColumnWrapCard: {
    height: `calc(100vh - 300px)`,
    overflowY: 'auto'
  },
  boardColumnWrapCardWithFooter: {
    height: `calc(100vh - 340px)`,
    overflowY: 'auto'
  },
  // Table and Card Text  
  pageTitleText: {
    fontSize: theme.typography.h4.fontSize,
    fontWeight: 700,
  },
  pageTitle2Text: {
    fontSize: theme.typography.h5.fontSize,
    fontWeight: 700,
  },
  titleText: {
    fontSize: theme.typography.body1.fontSize,
    fontWeight: 700,
  },
  primaryText: {
    fontSize: theme.typography.body1.fontSize,
    color: '#333',
  },
  secondaryText: {
    fontSize: theme.typography.body2.fontSize,
    color: '#888',
  },
  thirdText: {
    fontSize: theme.typography.body2.fontSize,
    color: '#888',
  },
  card: {
    backgroundColor: "white",
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1],
    // marginTop: theme.spacing(1),
    // marginBottom: theme.spacing(1),
  },
  cardSeparator: {
    height: theme.spacing(1),
  },
  selectableCardWrapper: {
    display: 'flex',
    flexDirection: 'row',
    '& > div': {
      width: '100%',
    }
  },
  listCheckbox: {
    
  },
  list: {

  },
  listItem: {
    marginBottom: theme.spacing(.5), 
  },
  listItemButtonSelected: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: theme.shape.borderRadius,
    overflow: 'hidden',
    color: 'white',
  },
  listItemIcon: {
    width: '2rem',
    display: 'flex',
    alignItems: 'center',
    flexShrink: 0,
  },
  listItemText: {
    flexGrow: 1,
  },
  listItemLink: {
    color: 'black',
    paddingTop: theme.spacing(.5),
    paddingBottom: theme.spacing(.5),
    display: 'flex',
    alignItems: 'center',
  },
  listItemLinkSelected: {
    color: theme.palette.primary.main,
  },
  listItemMenu: {
    paddingTop: theme.spacing(.5),
    paddingBottom: theme.spacing(.5),
    display: 'flex',
    alignItems: 'center',
  },
  listItemMenuPrimary: {
    flexGrow: 1,
  },
  listItemMenuSecondary: {
    flexShrink: 0,
    paddingLeft: theme.spacing(1),
  },
  listItemNoMargin: {
    paddingLeft: '0 !important',
    paddingRight: '0 !important'
  },
  listItemNoMarginY: {
    paddingTop: '0 !important',
    paddingBottom: '0 !important'
  },
  // Form View
  formField: {
    flexGrow: 1,
    '& .rmdp-container': {
      display: 'inherit !important'
    }
  },
  formFieldGroup: {
    display: 'flex',
    alignItems: 'flex-start',
    width: "100%",
  },
  formFieldGroupColumn: {
    flexDirection: "column",
    alignItems: 'stretch',
  },
  formFieldGroupDivider: {
    width: theme.spacing(2),
    height: theme.spacing(1),
    flexShrink: 0,
  },
  // Basics
  button: {
    textTransform: 'none',
    fontSize: theme.typography.body1.fontSize,
    fontWeight: 400,
    alignItems: 'center',
    display: 'flex',
    height: 36,
    // This allow consistent padding on button
    minWidth: 0,
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
  },
  buttonSmall: {
    paddingLeft: theme.spacing(1.25),
    paddingRight: theme.spacing(1.25),
    height: 32,
  },
  buttonXsmall: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    height: 24,
  },
  buttonGroup: {
    textTransform: 'none',
    fontSize: theme.typography.body1.fontSize,
    fontWeight: 400,
    alignItems: 'center',
    height: 36,
    // This allow consistent padding on button
    minWidth: 0,
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
  },
  buttonGroupSmall: {
    paddingLeft: theme.spacing(1.25),
    paddingRight: theme.spacing(1.25),
    height: 32,
  },
  buttonLink: {
    color: theme.palette.primary.main,
  },
  buttonSizeSmall: {
    fontSize: theme.typography.body2.fontSize,
  },
  buttonInsetRightMargin: {
    marginRight: theme.spacing(-1.5),
  },
  buttonInsetLeftMargin: {
    marginLeft: theme.spacing(-1.5),
  },
  link: {
    color: 'inherit',
    textDecoration: 'none',
    // "&:focus, &:hover, &:visited, &:link, &:active": {
    //   textDecoration: 'none',
    // },
  },
  image: {
    width: '100%',
    height: 'auto',
  },
  imageFitHeight: {
    width: 'auto',
    height: '100%',
    // height: 'auto',
    maxWidth: 250
  },
  textField: {
  },
  textFieldFullWidth: {
    width: '100%',
  },
  textFieldInputUnderline: {
    "&:before": {
      borderBottom: `1px solid #ccc`,
    },
  },
  textFieldInputUnderlineSelected: {
    "&:before": {
      borderBottom: `2px solid ${theme?.palette.primary.main}`,
    }
  },
  textFieldInputLabel: {
    // Note: input label size will be shrinked from this size
    fontSize: "1.2rem",
  },
  textFieldInputTextSmall: {
    fontSize: theme.typography.body2.fontSize,
  },
  textFieldInputTextLarge: {
    fontSize: "1.2rem",
  },
  textFieldInputTextExtraLarge: {
    fontSize: "1.5rem",
  },
  textFieldInputTextRightAlignment: {
    textAlign: "right",
  },
  imageListInputWrapper: {
    position: 'absolute',
    overflowX: "scroll",
    overflowY: "hidden",
    width: '100%', // '500px',
  },
  imageListInput: {
    height: 150,
    marginTop: theme.spacing(2)
  },
  imageListInputSmall: {
    height: 80
  },
  imageListInputContent: {
    display: 'flex',
    alignItems: 'stretch',
    '&::-webkit-scrollbar': {
      width: theme.spacing(0.7),
      height: theme.spacing(0.7)
    },
    '&::-webkit-scrollbar-track': {
      borderRadius: theme.spacing(0.5),
      backgroundColor: '#f2f2f2'
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: theme.spacing(0.5),
      backgroundColor: '#ccc'
    },
  },
  imageListInputSpacing: {
    width: theme.spacing(1),
    flexShrink: 0,
  },
  imageInput: {
    height: 140,
    position: 'relative'
  },
  imageInputSmall: {
    height: 80,
    position: 'relative'
  },
  imageInputImage: {
    position: 'relative',
    height: "100%",
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  imageInputImageBorder: {
    border: '1px solid #dddddd',
  },
  imageInputDelete: {
    position: "absolute",
    right: theme.spacing(.5),
    top: theme.spacing(.5),
  },
  imageInputPlaceholder: {
    height: 140,
    width: 140,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px dashed #ff0000',
  },
  imageInputPlaceholderSmall: {
    height: 78,
    width: 90,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px dashed #ff0000',
    position: 'relative',
  },
  uploadProgress: {
    width: theme.spacing(12),
    paddingTop: theme.spacing(3)
  },
  uploadProgressSmall: {
    width: theme.spacing(8),
    paddingTop: theme.spacing(1)
  },
  cancelUpload: {
    position: 'absolute',
    top: theme.spacing(.7),
    left: theme.spacing(.7)
  },
  inputFieldFilled: {
    backgroundColor: "#efefef",
    padding: theme.spacing(.5),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    borderRadius: 6,
  },
  selectRemoveArrow: {
    // Need to add !important otherwise it won't override
    // material-ui Select padding
    paddingRight: "0px !important",
  },
  blackDisabled: {
    color: 'black',
    width: '100%'
  },
  muted: {
    color: '#888'
  },
  legendRadio: {
    fontWeight: 'bold',
    color: 'black',
    marginBottom: theme.spacing(1)
  },
  childItem: {
    marginLeft: theme.spacing(1)
  },
  limitLineCount: {
    display: "-webkit-box",
    overflow: "hidden",
    "-webkit-box-orient": "vertical",
    "-webkit-line-clamp": props => props.lineCount ?? 2,
  },
  noMinWidth: {
    minWidth: 'auto'
  },
  selectPadding: {
    paddingTop: '6px !important',
    paddingBottom: '7px !important'
  },
  formMargin: {
    marginTop: '16px',
    marginBottom: '8px'
  },
  // dialogs
  dialogTitle: {
    position: 'sticky',
    top: '0',
    zIndex: '1',
    background: 'white'
  },
  dialogContentSmall: {
    padding: '0 12px'
  },
  dialogWrapperSmall: {
    width: '100%'
  },
  dialogMarginSmall: {
    margin: '32px 0px'
  },
  pointer: {
    cursor: 'pointer'
  },
  editorToolbar: {
    padding: '0px !important',
    paddingTop: '4px !important',
    border: '0px !important',
    fontSize: '12px !important',
    marginBottom: '0px !important',
    position: 'sticky !important',
    top: '-4px !important',
    marginLeft: '-6px !important',
    backgroundColor: '#fff !important',
    zIndex: 99,
    '& .rdw-inline-wrapper': {
      marginBottom: '0px !important',
      marginLeft: '6px !important',
      gap: '4px !important',
      borderRight: '1px solid #ddd',
      paddingRight: '6px !important',
      '& .rdw-option-wrapper': {
        border: '0px !important',
        padding: '2px !important',
        margin: '0px !important',
        minWidth: '20px !important',
        borderRadius: '6px !important',
        '&:hover': {
          backgroundColor: '#ddd !important',
          boxShadow: 'none !important',
        }
      },
      '& .rdw-option-active': {
        boxShadow: 'none !important',
        backgroundColor: '#ddd !important',
      }
    },
    '& .rdw-list-wrapper': {
      marginBottom: '0px !important',
      marginLeft: '6px !important',
      gap: '4px !important',
      borderRight: '1px solid #ddd',
      paddingRight: '6px !important',
      '& .rdw-option-wrapper': {
        border: '0px !important',
        padding: '2px !important',
        margin: '0px !important',
        minWidth: '20px !important',
        borderRadius: '6px !important',
        '&:hover': {
          backgroundColor: '#ddd !important',
          boxShadow: 'none !important',
        }
      },
      '& .rdw-option-active': {
        boxShadow: 'none !important',
        backgroundColor: '#ddd !important',
      },
    },
    '& .rdw-link-wrapper': {
      marginBottom: '0px !important',
      marginLeft: '6px !important',
      gap: '4px !important',
      borderRight: '1px solid #ddd',
      paddingRight: '6px !important',
      '& .rdw-option-wrapper': {
        border: '0px !important',
        padding: '2px !important',
        margin: '0px !important',
        minWidth: '20px !important',
        borderRadius: '6px !important',
        '&:hover': {
          backgroundColor: '#ddd !important',
          boxShadow: 'none !important',
        }
      },
      '& .rdw-option-active': {
        boxShadow: 'none !important',
        backgroundColor: '#ddd !important',
      },
      '& .rdw-link-modal': {
        position: 'fixed !important',
        top: '50% !important',
        left: '50% !important',
        zIndex: '9999 !important',
        transform: 'translate(-50%, -50%) !important'
      }
    },
  },
  editorField: {
    '& .DraftEditor-root': {
      '& .public-DraftEditorPlaceholder-root': {
        '& .public-DraftEditorPlaceholder-inner': {
          fontSize: '14px !important'
        },
      },
      '& .DraftEditor-editorContainer': {
        margin: '12px 0px',
        '& .public-DraftEditor-content': {
          '& .public-DraftStyleDefault-block': {
            margin: '0px !important',
            fontSize: '14px !important'
          },
          '& .public-DraftStyleDefault-ol': {
            '& .public-DraftStyleDefault-orderedListItem:before': {
              fontSize: '14px !important'
            }
          }
        }
      }
    }
  },
  editorNotEmptyPlaceholder: {
    '& .DraftEditor-root': {
      '& .public-DraftEditorPlaceholder-root': {
        '& .public-DraftEditorPlaceholder-inner': {
          fontSize: '14px !important',
          display: 'none !important'
        },
      }
    }
  },
  // mention input
  // mentionInputField: {
  //   [`& .makeStyles-mentionInputField-202__highlighter`]: {
  //     border: 'none !important',
  //     margin: '6px 0px 7px !important'
  //   },
  //   [`& .makeStyles-mentionInputField-202__input`]: {
  //     border: 'none !important',
  //     padding: '6px 0px 7px !important',
  //     "&:focus": {
  //       outline: "none",
  //     },
  //   },
  //   [`& .jss202__highlighter`]: {
  //     border: 'none !important',
  //     margin: '6px 0px 7px !important'
  //   },
  //   [`& .jss202__input`]: {
  //     border: 'none !important',
  //     padding: '6px 0px 7px !important',
  //     "&:focus": {
  //       outline: "none",
  //     },
  //   },
  // },
  // richtext
  richTextStyle: {
    marginRight: '24px',
    "& a": {
      fontWeight: 800,
      color: '#0000EE'
    },
  },
  richTextStyleLine4: {
    overflow: 'hidden',
    height: '200px',
  },
  wrapSeeLessBody: {
    display: 'flex',
    justifyContent: 'center',
  },
  wrapSeeMoreBody: {
    boxSizing: 'border-box',
    position: 'absolute',
    height: '200px',
    bottom: '0px',
    right: 0,
    background: 'linear-gradient(rgba(255, 255, 255, 0) 0%, rgb(255 255 255) 100%)',
    left: 0,
    alignItems: 'flex-end',
  },
  wrapItemSelected: {
    width: '3px',
    height: '100%',
    position: 'absolute',
    bottom: 0,
    left: 5,
    top: 0,
    display: 'flex',
    alignItems: 'center'
    // padding: '10px 0'
  },
  itemSelected: {
    borderRadius: '10px',
    backgroundColor: theme?.palette.primary.main,
    width: '100%',
    height: '75%',
  },
  signatureFieldLabel: {
    marginBottom: theme.spacing(1)
  },
  btnClearSignature: {
    position: 'absolute',
    right: '6px',
    top: '4px',
    display: 'flex',
    flexDirection: 'row'
  },
  separatorFooter: {
    height: '1px',
    background: '#e0e0e0',
    width: '100%',
    margin: '10px 0px'
  },
  activityItem: {
    // margin: '0px 4px',
    alignItems: 'center',
    display: 'flex',
    // background: 'rgb(224, 224, 224)',
    // borderRadius: '15px',
    padding: '1px 4px',
    // borderRight: '1px solid #e0e0e0'
  }
}), { index: 1 })
// Don't forget to add index:1, otherwise makeStyles classes will be injected
// after material-ui base styles. Strangely, it happens only on production build.
// On development build, I don't need to add this.
// Reference: https://stackoverflow.com/questions/62473898

export default useStyles
