import React from 'react'
import clsx from 'clsx'
import {
  ButtonGroup as MuiButtonGroup,
} from '@material-ui/core'
import useStyles from './styles'

export const ButtonGroup = ({size, ...props}) => {
  const classes = useStyles()
  
  return (
    <MuiButtonGroup
      {...props}
      size={size}
      classes={{
        grouped: clsx(
          classes.buttonGroup,
          size == "small" && classes.buttonGroupSmall
        )
      }}
	  onClick={(event) => event.stopPropagation()}
      />
  )
}

export default ButtonGroup