import { createMuiTheme } from '@material-ui/core/styles'
import { green, grey, red } from '@material-ui/core/colors'

const rawTheme = createMuiTheme({
  typography: {
    fontSize: 13,
  },
})

const theme = {
  ...rawTheme,
  palette: {
    ...rawTheme.palette,
    primary: {
      ...rawTheme.palette.primary,
      main: '#ff0000',
      main: 'rgb(255, 111, 0)',
    },
    secondary: {
      ...rawTheme.palette.primary,
      main: 'rgb(255, 140, 0)',
      main: 'rgb(255, 179, 0)',
    },
    grey: {
      ...rawTheme.palette.grey,
      muted: rawTheme.palette.grey["600"],
    },
  },
  shape: {
    ...rawTheme.palette,
    borderRadius: 6,//8,
  }
}

export default theme