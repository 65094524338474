import * as types from '../types'

import createReducer from 'shared/utils/createReducer'

export const initialState = {
  isFetching: false,
  lastFetched: undefined,
  data: undefined
}

const setFetchingProjectList = (state, { isFetching=true }) => ({ ...state, isFetching})
const setProjectList = (state, { data, append=false }) => {
  if (append && state.lastFetched && state.data.offset + state.data.size == data.offset) {
  	// Note: We do not check for existing records in case data sort has changed
  	const records = [
  	  ...state.data.records,
  	  ...data.records,
  	]

  	return {
  	  ...state,
  	  isFetching: false,
  	  data: {
  	  	offset: state.data.offset,
  	  	size: state.data.size + data.size,
  	  	total: data.total,
  	  	records
  	  }
  	}
  }

  return {
  	...state,
  	isFetching: false,
  	data,
  	lastFetched: new Date()
  }
}

export const actionList = {
  [types.PROJECT_SET_FETCHING_PROJECT_LIST]: setFetchingProjectList,
  [types.PROJECT_SET_PROJECT_LIST]: setProjectList,
}

export const reducer = createReducer(initialState, actionList)
