const getStyleProgressStatus = (status) => {
   if (status) {
      let progressBackgroundColor = 'grey'
      let progressTextColor = 'white'

      if (status.progress > 0 && status.progress <= 10) {
         // open
         progressBackgroundColor = '#dfe1e6'
         progressTextColor = '#42526e'
      } else if (status.progress >= 90 && status.progress <= 100) {
         // done
         progressBackgroundColor = '#e3fcef'
         progressTextColor = '#006644'
         // progressBackgroundColor = '#00875a'
         // progressTextColor = 'white'
      } else if (status.progress > 10 && status.progress < 90) {
         // in progress
         progressBackgroundColor = '#deebff'
         progressTextColor = '#0747a6'
         // progressBackgroundColor = '#0052cc'
         // progressTextColor = 'white'
      } else {
         progressBackgroundColor = '#e0e0e0'
         progressTextColor = 'black'
      }

      return {
         padding: '0 10px',
         fontWeight: '500',
         borderRadius: '4px',
         backgroundColor: progressBackgroundColor,
         color: progressTextColor
      }
   } else {
      return {}
   }
}

export default getStyleProgressStatus