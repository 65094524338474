import * as types from '../types'

import createReducer from 'shared/utils/createReducer'

const initialState = {
  isFetching: false,
  lastFetched: undefined,
  data: undefined,
}

const setFetchingProjectDetail = (state, { isFetching=true }) => ({ 
  ...state, 
  isFetching,
})
const setProjectDetail = (state, { data }) => ({ 
  ...state, 
  isFetching: false, 
  data, 
  lastFetched: new Date(),
})


export const actionList = {
  [types.PROJECT_SET_FETCHING_PROJECT_DETAIL]: setFetchingProjectDetail,
  [types.PROJECT_SET_PROJECT_DETAIL]: setProjectDetail,
}

export const reducer = createReducer(initialState, actionList)