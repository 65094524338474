import { createStore, applyMiddleware, compose, combineReducers } from 'redux'
import { createLogger } from 'redux-logger'
import createSagaMiddleware from 'redux-saga'
import reducers from './reducers'
import sagas from './sagas'

import { createEventDispatcher } from 'shared/utils/eventDispatcher'
import { createConversationMiddleware, createProjectMiddleware } from './middlewares'

const eventSource = {}
const dispatchEvent = createEventDispatcher(eventSource)

const middlewares = []
const sagaMiddleware = createSagaMiddleware()
const conversationMiddleware = createConversationMiddleware(dispatchEvent)
const projectMiddleware = createProjectMiddleware(dispatchEvent)
middlewares.push(sagaMiddleware)
middlewares.push(conversationMiddleware)
middlewares.push(projectMiddleware)
middlewares.push(createLogger())

const enhancers = [applyMiddleware(...middlewares)]

const reducer = combineReducers(reducers)

const bindMiddlewares = () => {
  if (process.env.NODE_ENV !== 'production') {
    const { composeWithDevTools } = require('redux-devtools-extension')
    return composeWithDevTools(...enhancers)
  }
  return compose(...enhancers)
}

export default () => {
  const store = createStore(reducer, undefined, bindMiddlewares())
  sagaMiddleware.run(sagas)
  return { store, eventSource, dispatchEvent }
}