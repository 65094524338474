import createReducer from 'shared/utils/createReducer'
import * as types from '../types'


// Data structure
export const WS_STATUS = {
  Idle: "Idle",
  Connecting: "Connecting",
  Connected: "Connected",
  Disconnected: "Disconnected"
}

const initialState = {
  ws_status: WS_STATUS.Idle,
  host: undefined,
}

const setConnecting = (state, { host }) => ({ ...state, ws_status: WS_STATUS.Connecting, host })
const setConnected = (state, { host }) => ({ ...state, ws_status: WS_STATUS.Connected, host })
const setDisconnected = (state) => ({ ...state, ws_status: WS_STATUS.Disconnected })

export const reducer = createReducer(initialState, {
  [types.CONVERSATION_SET_WS_CONNECTING]: setConnecting,
  [types.CONVERSATION_SET_WS_CONNECTED]: setConnected,
  [types.CONVERSATION_SET_WS_DISCONNECTED]: setDisconnected,
})

export default reducer