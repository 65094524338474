import { loading_bubbles } from "assets/images"
import { Text } from "."

export function LoadingBubbles({ variant }) {

  if (variant === "body2")
    return (
      <Text variant="body2">
        <div style={{ position: "absolute" }}>
          <img src={loading_bubbles}
            style={{ maxWidth: 32, width: 32, marginTop: -8 }}
            />
        </div>
        &nbsp;
      </Text>
    )
  else
    return (
      <div>
        <img src={loading_bubbles}
          style={{ maxWidth: 32, width: 32 }}
          />
      </div>
    )
}

export default LoadingBubbles