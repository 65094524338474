export const orderStatuses = [
  {
    name: "Draft",
    allowDelete: true,
    allowCancel: false,
    allowEdit: true,
    allowPO: false,
    allowRevision: false,
    match: (order) => [
      "draft",
    ].includes(order.status.toLowerCase()),
    canConfirmPayment: (order) => order?.payments?.length > 0 && order?.payments[0]?.pay_url,
    canReceiveManualPayment: (order) => false,
  },
  {
    name: "Pending Payment",
    allowDelete: false,
    allowCancel: true,
    allowEdit: true,
    allowPO: false,
    allowRevision: true,
    match: (order) => [
      "pending payment",
    ].includes(order.status.toLowerCase()),
    canConfirmPayment: (order) => order?.payments?.length > 0 && order?.payments[0]?.pay_url,
    canReceiveManualPayment: (order) => order?.payment_term == "MANUAL",
  },
  {
    name: "Pending PO",
    allowDelete: false,
    allowCancel: true,
    allowEdit: false,
    allowPO: true,
    allowRevision: true,
    match: (order) => [
      "pending po",
    ].includes(order.status.toLowerCase()),
    canConfirmPayment: (order) => order?.payments?.length > 0 && order?.payments[0]?.pay_url,
    canReceiveManualPayment: (order) => false,
  },
  {
    name: "On Progress",
    allowDelete: false,
    allowCancel: false,
    allowEdit: false,
    allowPO: false,
    allowRevision: false,
    match: (order) => {
      return (!["pending payment", "pending po", "draft"].includes(order.status.toLowerCase()) && (order.completed_at === null))
    },
    canConfirmPayment: (order) => order?.payments?.length > 0 && order?.payments[0]?.pay_url,
    canReceiveManualPayment: (order) => false,
  },
  {
    name: "Completed",
    allowDelete: false,
    allowCancel: false,
    allowEdit: false,
    allowPO: false,
    allowRevision: false,
    match: (order) => [
      "completed",
    ].includes(order.status.toLowerCase()),
    canConfirmPayment: (order) => false,
    canReceiveManualPayment: (order) => false,
    canCancelOrder: (order) => order.payment_term == "MANUAL" && !order.require_fulfillment
  },
  {
    name: "Canceled",
    allowDelete: false,
    allowCancel: false,
    allowEdit: false,
    allowPO: false,
    allowRevision: false,
    match: (order) => [
      "canceled",
    ].includes(order.status.toLowerCase()),
    canConfirmPayment: (order) => false,
    canReceiveManualPayment: (order) => false,
  }
]
