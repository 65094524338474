import React from 'react'
import { Dialog, List, ListItem } from "shared/ui"
import { Storefront as StoreIcon } from '@material-ui/icons'
import { useMediaQuery, useTheme } from '@material-ui/core'

const StorePicker = ({ open, onClose, list }) => {
  const actionButtons = [
    {
      label: "Cancel",
      onClick: onClose,
    }
  ]

  const theme = useTheme()
  const smDown = useMediaQuery(theme.breakpoints.down("sm"))

  return (
    <Dialog title="Select store" {...{ open, onClose, actionButtons }}>
      <List>
        {list && list?.length > 0 && list.map((li, i) => (
          <ListItem
            component="li"
            key={i}
            onClick={li?.onClick}
            primary={
              <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                <div style={{ display: 'flex', alignItems: smDown ? 'flex-start' : 'center', flex: 1 }}>
                  <StoreIcon />
                  <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 16 }}>
                    {li?.label}
                  </div>
                </div>
              </div>
            }
          />
        ))}
      </List>
    </Dialog>
  )
}

export default StorePicker
