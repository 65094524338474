import { get, post } from 'api/request'

export const API_SALES = "/sales/service"

export const getStoreList = () => get(`${API_SALES}/store`)
export const getActiveShifts = () => get(`${API_SALES}/shift/active_shifts`)
export const startShift = (startParams) => post(
  `${API_SALES}/shift/start_shift`,
  startParams    
)
export const endShift = (key, endParams) => post(
  `${API_SALES}/shift/${key}/end_shift`,
  endParams
)
