import ls from 'local-storage'
import React, { useState, useEffect, useContext } from 'react'
import { useMediaQuery, useTheme, Divider, Popover } from '@material-ui/core'
import { useDispatch, useSelect } from 'react-redux'
import { useLocation, useHistory } from "react-router-dom"
import useStyles from './styles'
import ScrollToTop from './ScrollToTop'
import NavigationBar from './NavigationBar'
import BottomNavBar from './BottomNavBar'
import { backgroundImages } from 'fixtures/backgroundImages'
import { MANAGE_ACCOUNT_URL } from 'fixtures/apiUrls'
//import ResizeDetector from 'react-resize-detector'
import useWindowSize from 'shared/utils/useWindowSize'
import { useAuth, useConversationWSStatus, WS_STATUS } from '../store/selectors'
import { AUTH_ACTION_LOGOUT } from '../store/types'
import {
  Box,
} from '@material-ui/core'
import {
  Search as SearchIcon,
  Business as BusinessIcon,
  Storefront as StoreIcon,
  AccountCircle as UserIcon,
  Settings as SettingsIcon,
  Check as CheckIcon,
  ArrowDropDown as DropdownIcon,
  Assignment as ProjectIcon
} from '@material-ui/icons'
import { appList } from '../apps'
import { ViewContext, MenuPopover, Button } from 'shared/ui'
import { useAppContext } from 'App'
import {
  STORE_ACTION_GET_ACTIVE_SHIFT,
  STORE_ACTION_START_SHIFT,
  STORE_ACTION_GET_STORE_LIST,
  QUEUE_ACTION_GET_QUEUES,
  QUEUE_ACTION_SET_AVAILABILITY,
  PROJECT_ACTION_GET_PROJECT_LIST
} from '../store/types'
import {
  useStoreList, useQueueList, useProjectList
} from '../store/selectors'
import StorePicker from './StorePicker'
import ShiftForm from './ShiftForm'
import clsx from 'clsx'

export const NavigationContext = React.createContext({})

const NavigationController = (props) => {

  const { children } = props
  // const business = useSelector((state) => getBusinessInfo(state));
  const appContext = useAppContext()
  const dispatch = useDispatch()
  const theme = useTheme()
  const classes = useStyles()
  const location = useLocation()
  const history = useHistory()
  const smDown = useMediaQuery(theme.breakpoints.down("sm"))

  const auth = useAuth()

  // popover state for submenus ---------------------------------- //
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null)
  const [popoverMenuItems, setPopoverMenuItems] = useState([])
  const [openSubmenu, setOpenSubmenu] = useState(false)
  const [appListMenus, setAppListMenus] = useState({})

  const [closePopoverNotification, setClosePopoverNotification] = useState(false)

  const showPopoverMenu = (target, q) => {
    const submenus = []
    const handleClick = (availability) => {
      dispatch({
        type: QUEUE_ACTION_SET_AVAILABILITY,
        key: q?.uid,
        availability,
        completeCallback: () => dispatch({ type: QUEUE_ACTION_GET_QUEUES })
      })
      setClosePopoverNotification(prev => !prev)
    } 
    const available = { label: 'Available', onClick: () => handleClick('available') }
    const unavailable = { label: 'Not Available', onClick: () => handleClick('not-available') }
    if (q?.my_availability?.availability === 'available') {
      available.icon = CheckIcon
      unavailable.useSpacer = true
    } else {
      unavailable.icon = CheckIcon
      unavailable.useSpacer = true
    }
    // } else if (q?.my_availability?.availability === 'not-available') {
    //   unavailable.icon = CheckIcon
    //   available.useSpacer = true
    // } else {
    //   available.useSpacer = true
    //   unavailable.useSpacer = true
    // }

    submenus.push(available)
    submenus.push(unavailable)

    setPopoverMenuItems(submenus)
    setPopoverAnchorEl(target)
    setOpenSubmenu(true)
  }

  const hidePopoverMenu = () => {
    setOpenSubmenu(false)
    setPopoverMenuItems([])
    setPopoverAnchorEl(null)
  }
  // popover submenu control block end ---------------------------------- //

  const logout = () => dispatch({ type: AUTH_ACTION_LOGOUT })

  const manageAccount = () => {
    if (window) {
      const url = `${MANAGE_ACCOUNT_URL}/?callback=${window.location.href}`
      window.open(url, '_blank')
    }
  }

  const isHome = location.pathname.startsWith("/home")
  const isInbox = location.pathname.startsWith("/conversations") && location.pathname.includes("view")
  const isTicketDetail = location.pathname.startsWith("/service") && location.pathname.includes("view") && location.pathname.split('/').length == 6
  const hideNavBar = smDown && !isHome

  const navigationContext = {}

  const [windowWidth, windowHeight] = useWindowSize()
  const defaultViewContext = useContext(ViewContext)
  const navBarHeight = hideNavBar ? 0 : 32
  // const navBarHeight = 32
  const bottomNavBarHeight = (smDown && !isInbox && !isTicketDetail) ? 56 : 0

  const [windowHeightMobile, setWindowHeightMobile] = useState(0)
  const isUseWindowMobile = smDown && windowHeightMobile && (isInbox || isTicketDetail)
  
  useEffect(() => {
    if(smDown && (isInbox || isTicketDetail)){
      setWindowHeightMobile(window.innerHeight)
    }
  }, [smDown, isInbox, isTicketDetail])

  const viewContext = {
    ...defaultViewContext,
    containerInsets: { top: navBarHeight, left: 0, right: 0, bottom: 0 },
    containerSize: { 
      width: windowWidth, 
      height: isUseWindowMobile ? 
              windowHeightMobile : 
              windowHeight - navBarHeight - bottomNavBarHeight}
  }

  const defaultIndexBackground = 2
  const [backgroundImage, setBackgroundImage] = useState(backgroundImages[defaultIndexBackground])
  const [indexBackgroundImage, setIndexBackgroundImage] = useState(defaultIndexBackground)
  const [showThemes, setShowThemes] = useState(false)
  const handleChangeThemes = (index) => {
    setIndexBackgroundImage(index + 1)
    setBackgroundImage(backgroundImages[index])
    if(backgroundImages[index]?.color)
      document?.querySelector('meta[name="theme-color"]').setAttribute('content', backgroundImages[index]?.color)

    localStorage.setItem('BG_IDX', index)
  }

  useEffect(() => {
    const backgroundIndex = localStorage.getItem('BG_IDX') || defaultIndexBackground;
    if (backgroundIndex) {
      setIndexBackgroundImage(Number(backgroundIndex) + 1)
      setBackgroundImage(backgroundImages[Number(backgroundIndex)])
      if(backgroundImages[Number(backgroundIndex)]?.color)
        document?.querySelector('meta[name="theme-color"]').setAttribute('content', backgroundImages[Number(backgroundIndex)]?.color)
    }
  }, [])

  const [storeItems, setStoreItems] = useState([])
  const [queueItems, setQueueItems] = useState([])
  const [showStorePicker, setShowStorePicker] = useState(false)
  const [shiftForm, setShiftForm] = useState(null)

  let menuItems = []
  // menuItems.push({
  //   title: "Home",
  //   linkTo: "/"
  // })
  menuItems.push(...appList.filter(app => {
    if (app.hideAppIcon)
      return false

    let has_perms = true

    if (app.required_permissions) {
      for (const perm of app.required_permissions) {
        has_perms = false
        const [module_name, perm_name] = perm.split(".")
        if (auth.userInfo?.permissions && auth.userInfo.permissions[module_name]) {
          if (auth.userInfo.permissions[module_name]["*"])
            has_perms = true
          else if (auth.userInfo.permissions[module_name][perm_name])
            has_perms = true
        }

        if (!has_perms)
          break
      }
    }

    return has_perms
  }).map(app => ({
    title: app.name,
    icon: app.icon,
    linkTo: appListMenus[app?.action_key]?.length == 1 ? appListMenus[app?.action_key][0].linkTo : ("/" + app.path),
    actionItems: appListMenus[app?.action_key] || []
  }))
  )

  menuItems = menuItems.map(item => {
    if (location.pathname === item.linkTo)
      return { ...item, selected: true }
    if (item.linkTo !== "/" && location.pathname.startsWith(item.linkTo))
      return { ...item, selected: true }
    return item
  })

  useEffect(() => {
    dispatch({ type: STORE_ACTION_GET_STORE_LIST })
    dispatch({ type: QUEUE_ACTION_GET_QUEUES })
    dispatch({ type: PROJECT_ACTION_GET_PROJECT_LIST })

    dispatch({
      type: STORE_ACTION_GET_ACTIVE_SHIFT,
      completeCallback: (ok, data) => {
        if (ok)
          appContext.setActiveShift(data)
        else        
          appContext.setActiveShift(null)
      },
    })
  }, [])

  const wsStatus = useConversationWSStatus()
  const storeList = useStoreList()
  const queueList = useQueueList()
  const projectList = useProjectList()

  useEffect(() => {
    if (storeList && storeList?.lastFetched) {
      if (auth?.userInfo?.server_env != "dev") {
        let persistStore = ls.get('_activeStore')
        if (persistStore) {
          const finder = storeList.data.filter(s => s?.uid === persistStore?.uid)
          if (finder && finder?.length > 0)
            appContext.setActiveStore(persistStore)
        }
        // disable store auto selection
        // else {
        //   appContext.setActiveStore(storeList.data[0])
        // }
      }

      const filteredStores = getFilteredStores(storeList.data)

      setStoreItems(filteredStores.map(store => ({
        label: `${store.store_code} - ${store.name}`,
        id: store.uid,
        data: store,
        onClick: () => {
          if (auth?.userInfo?.server_env == "dev") {
            // Start shift
            setShiftForm({
              initialData: {
                store: store,
                channels: store.channels.filter(c => c?.channel_type === 'offline')
              }
            })

            setShowStorePicker(false)   
          }
          else {
            appContext.setActiveStore(store)
            ls.set('_activeStore', store)
            setShowStorePicker(false)            
          }
        }
      })))
      appContext.setStoreItems(storeList.data.map(store => ({
        label: `${store.store_code} - ${store.name}`,
        id: store.uid,
        data: store,
        onClick: () => {
          appContext.setActiveStore(store)
          ls.set('_activeStore', store)
          // setShowStorePicker(false)
        }
      })))

      // auto select if eligible store length equals only 1
      if (filteredStores?.length === 1) {
        appContext.setActiveStore(filteredStores[0])
      }
    }
  }, [storeList])

  useEffect(() => {
    if (queueList.lastFetched) {
      const qitems = []
      queueList?.data?.forEach(q => {
        if (!q?.my_availability)
          return

        qitems.push({
          // heading: q?.name
          label: q?.name,
          onClick: (event) => showPopoverMenu(event?.target, q),
          icon: () => renderBadge(q?.my_availability),
          dontCloseParent: true
        })
      })

      if (qitems.length)
        qitems.push({ divider: true })
      setQueueItems(qitems)
    }
  }, [queueList, wsStatus])

  useEffect(() => {
    if (projectList?.lastFetched) {
      const pitems = projectList?.data?.records?.map(p => {
        const linkTo = `/service/project/${p?.key}`
        return{
          label: p?.name,
          onClick: () => history.push(linkTo),
          linkTo,
          icon: ProjectIcon
        }
      })
      setAppListMenus({
        ...appListMenus,
        projectList: pitems
      })
      appContext.setProjectsCtx(projectList?.data?.records)
    }
  }, [projectList])

  let notificationItems = []

  const WS_STATUS_CLASSNAME = {
    [WS_STATUS.Connected]: classes.userBadgeAvailable,
    "not_available": classes.userBadgeNotAvailable,
    "*": classes.userBadgeOffline,
  }

  const customThemes = () => {
    const themes = []
    for (let i = 0; i < backgroundImages.length; i += 1) {
      themes.push({ label: `Background ${i + 1}`, onClick: () => handleChangeThemes(i) })
    }
    return themes
  }

  const getStoreCodeName = (store) => `${store.store_code} - ${store.name}`

  const getFilteredStores = (stores) => stores.filter(s => {
    return (s.channels.some(c => c?.channel_type === 'offline'))
  }).sort((a, b) => {
    if (a.store_code && b.store_code)
      return a.store_code.localeCompare(b.store_code)
    else if (a.store_code && !b.store_code)
      return 1
    else if (!a.store_code && b.store_code)
      return -1
    else
      return a.name.localeCompare(b.name)
  })

  let badgeClassName = classes.userBadgeOffline
  if (wsStatus === WS_STATUS.Connected) {
    if (queueList?.data?.find(q => q?.my_availability?.availability === "available"))
      badgeClassName = classes.userBadgeAvailable
    else
      badgeClassName = classes.userBadgeNotAvailable
  }

  // reactivate navbar store selection
  if (storeList.lastFetched) {
    const filteredStores = getFilteredStores(storeList.data)
    if (filteredStores?.length > 0) {
      if (auth?.userInfo?.server_env == "dev")
        notificationItems.push({
          icon: StoreIcon,
          label: appContext.activeShift ? getStoreCodeName(appContext.activeShift.store) : "Start Shift",
          onItemClick: () => {
            if (appContext.activeShift)
              setShiftForm({
                shift: appContext.activeShift,
                initialData: {
                  ...appContext.activeShift
                }
              })
            else
             setShowStorePicker(true)
          }
        })
      else
        notificationItems.push({
          icon: StoreIcon,
          label: appContext.activeStore ? getStoreCodeName(appContext.activeStore) : "Select Store",
          // use filtered store containing 'offline' or 'salesagent' channels
          // actionItems: filteredStores.map(store => ({
          //   label: getStoreCodeName(store),
          //   onClick: () => {
          //     appContext.setActiveStore(store)
          //     ls.set('_activeStore', store)
          //   }
          // }))
          onItemClick: () => setShowStorePicker(true)
          // onItemClick: () => appContext.showModalView('catalog', 'store-catalog', {
          //   title: appContext.activeStore ? appContext.activeStore.name : "Store Catalog"
          // })
        })
    }

    // notificationItems.push({
    //   icon: DropdownIcon,
    //   onItemClick: () => setShowStorePicker(true)
    // })
  }

  notificationItems.push({
    icon: UserIcon,
    label: `${auth.userInfo.first_name} ${auth.userInfo.last_name || ""}`.trim(),
    badgeVariant: "dot",
    badgeOverlap: "circle",
    // badgeClassName: WS_STATUS_CLASSNAME[wsStatus] || WS_STATUS_CLASSNAME["*"],
    badgeClassName,
    actionItems: [
      ...queueItems,
      { label: "Manage Account", onClick: manageAccount },
      // { label: "Background", onClick: () => setShowThemes(true), dontCloseParent: true },
      { label: "Logout", onClick: logout },
    ],
  })

  const getBadgeClass = (availability, isOnline) => {
    if (isOnline && availability === "available") {
      return classes.userBadgeAvailable
    } else if (isOnline) {
      return classes.userBadgeNotAvailable
    } else if (!isOnline) {
      return classes.userBadgeOffline
    }
  }

  const renderBadge = (data) => {
    // const { availability, is_online } = data
    const { availability } = data
    const is_online = wsStatus === WS_STATUS.Connected
    return (
      <div className={clsx(classes.badgeShape, getBadgeClass(availability, is_online))} />
    )
  }

  let title = "GOAPP"
  if (auth?.userInfo?.server_env)
    title = `${title} (${auth?.userInfo?.server_env})`

  useEffect(() => {
    // document.addEventListener("click", (event) => {
    //   const isClickInside = document.getElementById('btn-set-theme').contains(event.target);
    //   console.log('isClickInside', isClickInside)
    //   if (!isClickInside) {
    //     console.log('!isClickInside')
    //     setShowThemes(false)
    //   }
    // })
  }, [])

  const ButtonSelectThemes = () => {
    if(location.pathname !== '/home') return <></>

    return (
      <>
        <div className={classes.containerSelectThemes}>
          <Button id="btn-set-theme" variant="icon" kind="image-landscape" className={classes.buttonSelectTheme} onClick={() => {
            setShowThemes(!showThemes)
          }}>Background</Button>
          {/* <MenuPopover
            menuItems={customThemes()}
            open={showThemes}
            anchorEl={"selectThemes"}
            onClose={() => setShowThemes(false)}
            // PopoverClasses={{ root: classes.submenu }}
          /> */}
        </div>
        {
          showThemes &&
          <div className={classes.contentPopoverTheme}>
            {
              customThemes()?.map(data => (
                <div style={{display: 'flex'}} onClick={() => {
                  data.onClick()
                }}>
                  {data.label} {data.label == `Background ${Number(indexBackgroundImage)}` ? <CheckIcon /> : '' } 
                </div>
              ))
            }
          </div>
        }
      </>
    )
  }

  return (
    <div className={classes.root}>
      <ViewContext.Provider value={viewContext}>
        <NavigationContext.Provider value={navigationContext}>
          {smDown ?
            <ScrollToTop>
              {!hideNavBar &&
                <>
                  <div className={classes.navigationBarBackground}
                    style={{
                      backgroundColor: "#aaa",
                    }}
                  />

                  <NavigationBar
                    title="SMARTDesk"
                    menuItems={menuItems}
                    notificationItems={notificationItems}
                    closePopoverNotification={closePopoverNotification}
                  />
                  <div className={classes.navigationBarHeight} />
                </>
              }

              <div className={classes.content}>
                {children}
                <div className={classes.bottomNavBarHeight} />
              </div>
              {!isInbox && !isTicketDetail && <BottomNavBar menuItems={menuItems} />}
            </ScrollToTop>
            :
            <ScrollToTop>
              {backgroundImage.imageUrl &&
                <div className={classes.backgroundImageOverlay}
                  style={{ backgroundImage: `url("${backgroundImage.imageUrl}")` }}
                >
                </div>
              }

              <div className={classes.navigationBarBackground}
                style={{
                  backgroundColor: backgroundImage.color,
                }}
              >
                <div className={classes.navigationBarBackground}
                  style={{
                    opacity: ".3",
                    backgroundImage: `url("${backgroundImage.imageUrl}")`,
                    backgroundSize: '100% auto',
                    backgroundRepeat: 'no-repeat',
                    filter: "blur(10px)",
                    transform: "scale(1.2)"
                  }}>
                </div>
              </div>
              <NavigationBar
                title="SMARTDesk"
                menuItems={menuItems}
                notificationItems={notificationItems}
                closePopoverNotification={closePopoverNotification}
              />

              <div className={classes.navigationBarHeight} />
              {/* Warning: Do not use <main> tag. It's not supported in IE */}
              <div className={classes.content}>
                {children}
              </div>
              <div>
                <ButtonSelectThemes />
              </div>
            </ScrollToTop>
          }
          {storeItems && storeItems?.length > 0 && showStorePicker &&
            <StorePicker
              open={showStorePicker}
              onClose={() => setShowStorePicker(false)}
              list={storeItems}
            />
          }
          {shiftForm &&
            <ShiftForm 
              open={true}
              onClose={() => setShiftForm(null)}
              onStart={(shift) => {
                appContext.setActiveShift(shift)
                setShiftForm(null)
              }}
              onEnd={() => {
                appContext.setActiveShift(null)
                setShiftForm(null)
              }}
              {...shiftForm}
            />
          }

        </NavigationContext.Provider>
      </ViewContext.Provider>
      <MenuPopover
        menuItems={popoverMenuItems}
        open={openSubmenu}
        anchorEl={popoverAnchorEl}
        onClose={hidePopoverMenu}
        PopoverClasses={{ root: classes.submenu }}
      />
    </div>
  )
}

export default NavigationController
