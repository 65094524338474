import clsx from 'clsx'
import { useTheme } from "@material-ui/core"
import { withResizeDetector } from 'react-resize-detector'
import {
  ImageInput,
} from '.'
import useStyles from './styles'

function ImageListInputInner({value, width, height, onChange, onUploadImage}) {
  const classes = useStyles()
  const theme = useTheme()

  return (
    <div className={classes.imageListInput}>  
      <div 
        className={classes.imageListInputContent}
        style={{ 
          // position: "absolute",
          width: `${width}px`,
          height: `${height}px`,
          overflowX: "scroll",
          overflowY: "hidden",
        }}
        >
        {value && value.map((image, index) =>
          <ImageInput value={image} 
            allowDelete={true}
            onDelete={() => {
              let list = [...value]
              list.splice(index, 1)
              onChange(list)
            }}
            />
          ).reduce((p, n) => p ?
            [...p, <div className={classes.imageListInputSpacing} />, n] : [n]
            , null
        )}
        {value &&
          <div className={classes.imageListInputSpacing} />
        }
        <ImageInput onUploadImage={onUploadImage} />
      </div>
    </div>
  )
}

export const ImageListInput = withResizeDetector(ImageListInputInner)

export default ImageListInput