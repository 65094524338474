function isCurrencyOrNumber(input) {
   // Convert the input to a string for consistent processing
   var inputString = input.toString();
 
   // Remove currency symbols and commas
   var cleanedInput = inputString.replace(/[$£€¥₹,.]/g, '');
 
   // Parse the cleaned input as a float or integer
   var num = parseFloat(cleanedInput);
 
   // Check if the parsed value is a number and not NaN
   return !isNaN(num) && isFinite(num);
 }

 export default isCurrencyOrNumber