import { useEffect, useRef, useState } from 'react'
import useStyles from './styles'
import Button from './Button'
import SignaturePad from 'react-signature-pad-wrapper'

const SignatureInput = (props) => {
   const classes = useStyles()
   const signatureRef = useRef(null)
   const {
      name,
      onUploadImage
   } = props

   const [isUploadLoading, setIsUploadLoading] = useState(false)
   const [isSignatureEmpty, setIsSignatureEmpty] = useState(true)
   const [isUploaded, setIsUploaded] = useState(false)

   useEffect(() => {
      if(!signatureRef?.current) return

      const signaturePad = signatureRef?.current?.signaturePad

      signaturePad?.addEventListener("beginStroke", () => {
         setIsSignatureEmpty(false)
            setIsUploaded(false)
       })

      return () => {
         signaturePad?.removeEventListener("beginStroke", () => {
            setIsSignatureEmpty(false)
            setIsUploaded(false)
          })
      }
   }, [signatureRef?.current])
   

   const dataURLtoFile = (dataUrl, fileName) => {
      // Decode the data URL to a byte string
      const byteString = atob(dataUrl.split(',')[1]);
  
      // Create an array of 8-bit unsigned integers
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
      }
  
      // Determine the MIME type of the data URL
      const mimeString = dataUrl.split(',')[0].split(':')[1].split(';')[0];
  
      // Create a Blob from the byte array
      const blob = new Blob([ab], { type: mimeString });
  
      // Create a File from the Blob
      const file = new File([blob], fileName, { type: mimeString });
  
      return file;
    }

   return (
      <div style={{position: 'relative'}}>
          <SignaturePad ref={signatureRef} height={200}  options={{backgroundColor: 'whitesmoke', minWidth: 1, maxWidth: 1, }}/>
          {
            signatureRef?.current &&
            !isSignatureEmpty &&
            <div className={classes.btnClearSignature}>
               <Button 
                  kind="delete" 
                  variant="icon" 
                  alt="delete"
                  color="default"
                  onClick={() => {
                     signatureRef.current?.clear()
                     setIsSignatureEmpty(true)
                  }}
               />
               {
                  !isUploaded &&
                  <Button 
                     kind="done" 
                     variant="icon" 
                     alt="done"
                     color="default"
                     loading={isUploadLoading}
                     onClick={() => {
                        if(onUploadImage){
                           setIsUploadLoading(true)
                           onUploadImage(
                              dataURLtoFile(signatureRef.current?.toDataURL(), `${name}.png`), 
                              name,
                              () => {
                                 setIsUploadLoading(false)
                                 setIsUploaded(true)
                              }
                           )
                        }
                     }}
                  />
               }
            </div>
          }
        </div>
   )
}

export default SignatureInput