import * as types from '../types'
import createReducer from 'shared/utils/createReducer'
import moment from 'moment'

export const initialState = {
  isFetching: false,
  lastFetched: undefined,
  data: undefined
}

const setFetchingStoreList = (state, { isFetching=true }) => ({ ...state, isFetching})
const setStoreList = (state, { data }) => ({ 
  ...state, 
  isFetching: false, 
  data,
  lastFetched: new Date()
})

export const actionList = {
  [types.STORE_SET_FETCHING_STORE_LIST]: setFetchingStoreList,
  [types.STORE_SET_STORE_LIST]: setStoreList,
}

export const reducer = createReducer(initialState, actionList)
export default reducer