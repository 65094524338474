import React, { useRef, forwardRef } from 'react'
import { useHistory } from 'react-router-dom'
import { useSnackbar, SnackbarContent } from 'notistack'
import { Card, CardSubtitle, CardTitle, Text } from 'shared/ui'
import useStyles from './styles'
import { string } from 'prop-types'

const Notification = forwardRef((props, ref) => {
  const { msg, body, data } = props
  const history = useHistory()
  const classes = useStyles()

  const getSenderName = () => {
    const sender = data?.sender?.contact || data?.sender?.agent
    if (sender) {
      let name = sender?.first_name || ''
      if (sender?.last_name)
        name += " " + sender?.last_name || ''
      if (!name || name.length <= 0)
        name = data?.sender?.address || `Contact ${sender?.uid % 9999}`
      return name
    } else return "Unknown"
  }

  const navigateToConversation = () => {
    const { conversation } = data
    history.push(`/conversations/${conversation.groups[0]}/view/${conversation.uid}`)
  }

  return (
    <SnackbarContent ref={ref} className={classes.root}>
      <div className={classes.notificationCard} onClick={navigateToConversation}>
        <Text style={{ fontWeight: 700 }}>
          {msg}
        </Text>
        <Text
          variant="body2"
          color="textSecondary"
          className={classes.notificationCardBody}
        >
          {body ||
            <>
            {getSenderName()}: {data?.body}
            </>
          }
        </Text>
      </div>
    </SnackbarContent>
  )
})

export default Notification