import React, { useState, useEffect } from 'react'
import { withResizeDetector } from 'react-resize-detector'
import { Box, LinearProgress, useTheme } from "@material-ui/core"
import { Description } from "@material-ui/icons"
import { Text, Button } from '.'
import clsx from 'clsx'
import useStyles from './styles'

const FilePreview = ({ value, media, allowDelete, onDelete, index }) => {
  const classes = useStyles()

  const [image, setImage] = useState(null)

  useEffect(() => {
    let reader = new FileReader();
    reader.onload = (e) => {
      setImage(e.target.result)
    };
    reader.readAsDataURL(media);
  }, media)

  const del = (e) => {
    e.stopPropagation()
    let list = [...value]
    list.splice(index, 1)
    onDelete(list)
  }

  return (
    <div className={classes.imageInputImage} key={`preview-${image?.name}`}>
      {allowDelete &&
        <div className={classes.imageInputDelete}>
          <Button kind="delete" variant="icon" size="small" onClick={del} />
        </div>
      }
      <img className={classes.imageFitHeight} src={image} alt={`preview-${image?.name}`} />
    </div>
  )
}

const FileListViewInner = (props) => {
  const {
    onDelete,
    value,
    width,
    height,
    useFileReader = false,
    useSmallThumbs = false,
    allowDelete,
    isUploading = false,
    onCancel = null,
    percentageProps = 0,
    className,
    textVariantNoAttachment = "body1",
    disableBorder = false
  } = props
  const classes = useStyles()
  const theme = useTheme()
  const imageExt = ['jpg', 'jpeg', 'png', 'bmp', 'gif', 'tiff', 'jfif']

  const del = (e, i) => {
    e.preventDefault()
    let list = [...value]
    list.splice(i, 1)
    onDelete(list)
  }

  if ((value && value.length > 0) || isUploading)
    return (
      <div className={clsx(classes.imageListInput, useSmallThumbs && classes.imageListInputSmall, className)} >
        <div
          className={classes.imageListInputContent}
          style={{
            // position: "absolute",
            width: "auto",
            height: `${height}px`,
            overflowX: "auto",
            overflowY: "hidden",
          }}
        >
          {value && value.map((media, index) =>
            <>
              {useFileReader
                ? <FilePreview {...props} {...{ media }} />
                : <a
                  href={media?.image_url}
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: 'none', color: 'black' }}
                  onClick={(e) => e.stopPropagation()}
                >
                  <div className={useSmallThumbs ? classes.imageListInputSmall : classes.imageInput} key={index}>
                    {media?.image_url &&
                      <>
                        {imageExt.some(ext => ext.includes(media?.image_url.split('.').pop()))
                          ? <div className={clsx(classes.imageInputImage, !disableBorder && classes.imageInputImageBorder)}>
                            {allowDelete &&
                              <div className={classes.imageInputDelete}>
                                <Button kind="delete" variant="icon" size="small" onClick={(e) => del(e, index)} />
                              </div>
                            }
                            <img className={classes.imageFitHeight} src={media?.image_url} alt={media?.file_name} />
                          </div>
                          : <div className={useSmallThumbs ? classes.imageInputPlaceholderSmall : classes.imageInputPlaceholder}>
                            <label htmlFor={'perform_upload'}>
                              {allowDelete &&
                                <div className={classes.imageInputDelete}>
                                  <Button kind="delete" variant="icon" size="small" onClick={(e) => del(e, index)} />
                                </div>
                              }
                              <Box display='flex' flexDirection='column' alignItems='center'>
                                <Description />
                                <Text variant="body2">
                                  {media?.file_name}
                                </Text>
                              </Box>
                            </label>
                          </div>
                        }
                      </>
                    }
                  </div>
                </a>
              }
            </>
          ).reduce((p, n) => p ?
            [...p, <div className={clsx(value.length > 1 && classes.imageListInputSpacing)} />, n] : [n]
            , null
          )}
          {value &&
            <div className={clsx(value.length > 1 && classes.imageListInputSpacing)} />
          }
          {isUploading &&
            <>
              <div className={useSmallThumbs ? classes.imageInputSmall : classes.imageInput}>
                <div className={useSmallThumbs ? classes.imageInputPlaceholderSmall : classes.imageInputPlaceholder}>
                  {onCancel &&
                    <div className={classes.cancelUpload}>
                      <Button
                        kind="cancel"
                        variant="icon"
                        size="small"
                        onClick={onCancel}
                      />
                    </div>
                  }
                  <Box display='flex' flexDirection='column' alignItems='center'>
                    <Description style={{ marginBottom: theme.spacing(1) }} />
                    <Text variant="caption" color='textMuted'>
                      {`${percentageProps}%`}
                    </Text>
                    <div className={useSmallThumbs ? classes.uploadProgressSmall : classes.uploadProgress}>
                      <LinearProgress />
                    </div>
                  </Box>
                </div>
              </div>
              <div className={classes.imageListInputSpacing} />
            </>
          }
        </div>
      </div>
    )

  return (
    <Text color="textSecondary" variant={textVariantNoAttachment}>No attachments</Text>
  )
}

export const FileListView = withResizeDetector(FileListViewInner)

export default FileListView