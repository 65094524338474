export const PROJECT_ACTION_WS_CONNECT = 'PROJECT_ACTION_WS_CONNECT'
export const PROJECT_ACTION_WS_DISCONNECT = 'PROJECT_ACTION_WS_DISCONNECT'
export const PROJECT_ACTION_WS_SEND = 'PROJECT_ACTION_WS_SEND'
export const PROJECT_SET_WS_CONNECTING = 'PROJECT_SET_WS_CONNECTING'
export const PROJECT_SET_WS_CONNECTED = 'PROJECT_SET_WS_CONNECTED'
export const PROJECT_SET_WS_DISCONNECTED = 'PROJECT_SET_WS_DISCONNECTED'

export const PROJECT_ACTION_GET_PROJECT_LIST = 'PROJECT_ACTION_GET_PROJECT_LIST'
export const PROJECT_ACTION_GET_PROJECT_DETAIL = 'PROJECT_ACTION_GET_PROJECT_DETAIL'
export const PROJECT_SET_FETCHING_PROJECT_LIST = 'PROJECT_ACTION_SET_FETCHING_PROJECT_LIST'
export const PROJECT_SET_FETCHING_PROJECT_DETAIL = 'PROJECT_ACTION_SET_FETCHING_PROJECT_DETAIL'
export const PROJECT_SET_PROJECT_LIST = 'PROJECT_ACTION_SET_PROJECT_LIST'
export const PROJECT_SET_PROJECT_DETAIL = 'PROJECT_ACTION_SET_PROJECT_DETAIL'
