import { useState, useEffect } from "react"

const useWindowSize = () => {
  const getWindowSize = () => [window.innerWidth, window.innerHeight]
  const [windowSize, setWindowSize] = useState(getWindowSize())

  useEffect(() => {
    function handleResize() {
      setWindowSize(getWindowSize())      
    }
    
    window.addEventListener("resize", handleResize)
   // Add visualViewport resize will handle some issues on iPad Safari
   // such as change zoom level and keyboard layout changed. These event
   // doesn't trigger window resize event
    window.visualViewport.addEventListener("resize", handleResize)
    
    return () => {
      window.removeEventListener("resize", handleResize)
      window.visualViewport.removeEventListener("resize", handleResize)      
    }
  }, [])

  return windowSize;
}

export default useWindowSize
