import ReactMarkdown from 'react-markdown'
import {
  useTheme,
} from '@material-ui/core'
import { Button, Text } from '.'
import useStyles from './styles'
import { Fragment, useEffect, useState } from 'react'
import cn from 'clsx'

const WrapLimitBody = ({children, wrapLimit, onClickMore, enableShowMore}) => {
  const classes = useStyles()

  if(wrapLimit){
    return (
      <div style={{position: 'relative', cursor: !enableShowMore ? 'pointer' : ''}} onClick={!enableShowMore && ((e) => onClickMore(e))}>
        {children}
        <div className={cn(classes.wrapSeeLessBody, wrapLimit && classes.wrapSeeMoreBody)}>
          {
            enableShowMore &&
            <Button 
              onClick={onClickMore && ((e) => onClickMore(e))}  
              color="primary"
              size="small">
                {wrapLimit ? "SHOW MORE" : "SHOW LESS"}
            </Button>
          }
        </div>
      </div>
    )
  } else if(enableShowMore){
    return (
      <div>
        {children}
        <div className={cn(classes.wrapSeeLessBody, wrapLimit && classes.wrapSeeMoreBody)}>
          {
            enableShowMore &&
            <Button 
              onClick={onClickMore && ((e) => onClickMore(e))}  
              color="primary"
              size="small">
                {wrapLimit ? "SHOW MORE" : "SHOW LESS"}
            </Button>
          }
        </div>
      </div> 
    )
  }
  
  return children
}

export const RichText = ({ source, format = "markdown", className, size, limitBody = false, onClickMore, enableShowMore = false, mentionList }) => {
  const [text, setText] = useState('')

  const classes = useStyles()
  const theme = useTheme()
  let customStyles = {
    wordBreak: "break-word",
  }

  const renderers = {
    paragraph: ({ children, index, parentChildCount }) => (
      <Text
        gutterBottom={index === parentChildCount - 1 ? false : true}
        variant={size === "small" ? "body2" : "body1"}
      >
        {children}
      </Text>
    ),
    image: ({ alt, src, title, }) => (
      <img
        alt={alt}
        src={src}
        title={title}
        style={{ maxWidth: 200 }}
      />
    ),
  }

  const escapeHtml = (unsafe) => {
    return unsafe
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/"/g, "&quot;")
      .replace(/'/g, "&#039;")
      .replace(/(?:\r\n|\r|\n)/g, '<br>')
  }

  const handleFormat = () => {
    const htmlFormat = [
      { symbol: '*', tag: 'b' },
      { symbol: '_', tag: 'em' },
      { symbol: '~', tag: 'del' },
      { symbol: '```', tag: 'code' },
      { symbol: '@', tag: 'a' },
    ]

    const sanitize = escapeHtml(source)
    let converter = sanitize;

    // check value formatted is address\agent?.uid mention
    const formattedMention = (txt, regex, tag) => txt.replace(regex, (match, p1) => {
      const findMention = mentionList?.find(f => f.address == p1 || f?.agent?.uid == p1)
      const nameMention = `${findMention?.contact?.first_name || ""} ${findMention?.contact?.last_name || ""}`.trim()
      
      return `<${tag}>${findMention ? nameMention : p1}</${tag}>`;
    })

    htmlFormat.forEach(({ symbol, tag }) => {
      // This is not supported in Safari
      // https://stackoverflow.com/questions/51568821
      // const regex = new RegExp(`(?<=\\${symbol})(.*?)(?=\\${symbol})`, 'gm')
      const regex = new RegExp(`\\${symbol}([^${symbol}]*)\\${symbol}`, 'gm')
      const regexSingleTag = new RegExp(`\\${symbol}(\\w+)`, 'gm');

      const match = source.match(regex);
      const matchSingleTag = source.match(regexSingleTag)

      const isSymbolMention = symbol == '@'

      if(!isSymbolMention && match){
        match.forEach(m => {
          let formatted = m;
          for (let i = 0; i < 2; i += 1) {
            formatted = formatted.replace(symbol, `<${i > 0 ? '/' : ''}${tag}>`);
          }
          converter = converter.replace(m, formatted)
        });
      } else if(match) {
        match.forEach(m => {
          converter = converter.replace(m, formattedMention(m, regex, tag))
        });

        // match.forEach(m => {
        //   if (m){
        //     let formatted = m;
        //     formatted = `<${tag}>${m}</${tag}>`
        //     converter = converter.replace(`${symbol}${m}${symbol}`, formatted)
        //   }
        // })
      } else if(matchSingleTag) {
        matchSingleTag.forEach(m => {
          converter = converter.replace(m, formattedMention(m, regexSingleTag, tag))
        });
      }
    })

    const regexUrl = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig
    converter = converter.replace(regexUrl, function(url) {
      return '<a target="_blank" rel="noreferrer" style="text-decoration: none" href="' + url + '">' + url + '</a>';
    })

    setText(converter)
  }

  useEffect(() => {
    if (source) handleFormat()
  }, [source])

  const [isLimitBody, setIsLimitBody] = useState(false)

  useEffect(() => {
    setIsLimitBody(limitBody)
  }, [limitBody])
  
  return format === 'markdown' ? (
    <WrapLimitBody 
      wrapLimit={isLimitBody} 
      onClickMore={() => setIsLimitBody(prev => !prev)} 
      enableShowMore={limitBody && enableShowMore}
    >
      <div className={cn(classes.richTextStyle, isLimitBody && classes.richTextStyleLine4)}>
        <ReactMarkdown
          source={source}
          renderers={renderers}
          includeNodeIndex={true}
        />
      </div>
    </WrapLimitBody>
  ) : (
    <WrapLimitBody 
      wrapLimit={isLimitBody} 
      onClickMore={() => setIsLimitBody(prev => !prev)} 
      enableShowMore={limitBody && enableShowMore}
    >
      <Text variant={size === "small" ? "body2" : "body1"} className={cn(className)}>
        <div className={cn(classes.richTextStyle, isLimitBody && classes.richTextStyleLine4)}
          dangerouslySetInnerHTML={{ __html: text }}
        />
      </Text>
    </WrapLimitBody>
  )
}

export default RichText