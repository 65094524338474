import * as types from '../types'

export const createConversationMiddleware = (dispatchEvent) => {
  let socket = null

  const onOpen = store => (event) => {
    console.log('websocket open', event.target.url);
    store.dispatch({
      type: types.CONVERSATION_SET_WS_CONNECTED,
      host: event.target.url,
    })

    // store.dispatch(wsConnected(event.target.url));
  }

  const onClose = store => () => {
    console.log('websocket close')
    socket = null
    store.dispatch({
      type: types.CONVERSATION_SET_WS_DISCONNECTED,
    })
  }

  const onMessage = store => (event) => {
    const payload = JSON.parse(event.data);
    console.log('receiving server message');

    if (dispatchEvent)
      dispatchEvent("message", payload)

    // alert("onMessage: " + payload.type + " with Data: " + JSON.stringify(payload.data, null, 2))

    switch (payload.type) {
      case 'new_message':
        store.dispatch({
          type: types.CONVERSATION_ON_WS_NEW_MESSAGE,
          data: payload.data,
        })
        // store.dispatch(receiveNewMessage(payload.data.conversation.uid, payload.data));        
        break;
      case 'messages_updated':
        //alert("onMessage: " + payload.type)
        store.dispatch({
          type: types.CONVERSATION_ON_WS_CONVERSATION_MESSAGES_CHANGE,
          data: payload.data,
        })
        // store.dispatch(receiveConversationMessagesUpdate(payload.data.conversation.uid, payload.data.conversation, payload.data.messages));        
        break;        
      case 'update_conversation':
        store.dispatch({
          type: types.CONVERSATION_ON_WS_CONVERSATION_CHANGE,
          data: payload.data,
        })
        // store.dispatch(receiveConversation(payload.data.uid, payload.data));

        // // Refresh conversation counts
        // // NOTE: This might be heavy for server, but it ensure that conversation
        // // counts are realtime
        // store.dispatch(fetchConversationCounts(true))
        break;
      case 'new_assignment':
        store.dispatch({
          type: types.CONVERSATION_ON_WS_CONVERSATION_CHANGE,
          data: payload.data,
        })
        break;        
      case 'agent_status_changed':
        store.dispatch({ 
          type: types.QUEUE_RECEIVE_AVAILABILITY_UPDATE,
          key: payload.data.queue.uid,
          data: payload.data,
        })                
        // // We're still using single queue mode
        // store.dispatch(receiveAvailability("0", payload.data));
        // //store.dispatch(receiveAvailability(payload.data.queue.uid, payload.data));
        break
      default:
        break
    }
  }

  // the middleware part of this function
  return store => next => action => {
    switch (action.type) {
      case types.CONVERSATION_ACTION_WS_CONNECT:
        if (socket !== null) {
//          socket.close();
          break
        }        

//        alert(action.host)
        // connect to the remote host
        socket = new WebSocket(action.host)
        
        store.dispatch({
          type: types.CONVERSATION_SET_WS_CONNECTING,
          host: action.host
        })
        // store.dispatch(wsStateChanged(WSSTATE.Connecting))

        // websocket handlers
        socket.onmessage = onMessage(store)
        socket.onclose = onClose(store)
        socket.onopen = onOpen(store)

        break;
      
      case types.CONVERSATION_ACTION_WS_DISCONNECT:
        if (socket !== null) {
          socket.close();
        }
        socket = null;
        
        console.log('websocket closed')
        store.dispatch({
          type: types.CONVERSATION_SET_WS_DISCONNECTED,
        })
        
        break
        
      // case 'WS_START':
      //   // Send start command

      // case 'WS_CONNECTED':
      //   store.dispatch(wsStateChanged(WSSTATE.Connected))
      //   break;
        
      // case 'WS_CONNECTING':
      //   store.dispatch(wsStateChanged(WSSTATE.Connecting))
      //   break;
        
      // case 'WS_DISCONNECTED':
      //   store.dispatch(wsStateChanged(WSSTATE.Disconnected))
      //   socket = null          
      //   break;
        
      case types.CONVERSATION_ACTION_WS_SEND:
        if (socket == null)
          break
        console.log('sending a message: ', action.command)
        socket.send(JSON.stringify({ command: action.command, data: action.data }))
        break
        
      default:
        console.log('the next action:', action)
        return next(action)
    }
  }
}

export default createConversationMiddleware