import React from 'react'
import { useMediaQuery, useTheme } from '@material-ui/core'
import {
  Box,
} from '@material-ui/core'
import Text from './Text'
import List from './List'
import useStyles from './styles'
import Button from './Button'

export const Section = ({ variant, children, ...props}) => {
  const classes = useStyles()
  
  const {title, titleButtons, gutterBottom} = props

  const content = React.Children.map(children, e => {
    // Add custom wrapper to enable full bleed inside panel
    // to some known element. We should do this in CSS using 
    // nested selected, but don't know how to do this in material-ui yet
    if (React.isValidElement(e)) {
      if (e.type == List)
        return (<div className={classes.panelList}>{e}</div>)
    }
    
    return e
  })  
  
  return (
    <Box mb={gutterBottom ? (variant === 'subsection' ? 2 : 4) : 0}
      className={classes.section}
      >
      {title &&
        <Box 
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          className={variant === 'subsection' ? classes.subsectionHeader : classes.sectionHeader}>
          <Text 
            component={variant === 'subsection' ? 'h5' : 'h4'}
            className={variant === 'subsection' ? classes.subsectionTitleText : classes.sectionTitleText}>
            {title}
          </Text>
          <Box display="flex">
            {titleButtons && titleButtons?.map(button => (
              <Button {...button}
                onClick={(event) => { event.stopPropagation(); button.onClick(); }}
              />
            ))}
          </Box>
        </Box>
      }
      {content}
      {/*
      <Box mt={variant === 'subsection' ? 1 : 2} mb={variant === 'subsection' ? 1 : 2}>
        { children }
      </Box>
      */}
    </Box>
  )
}

export default Section