function formatPhoneNumber(input) {
  // Remove any non-numeric characters from the input
  const phoneNumber = input

  // Check if the phone number starts with '62', if not, add it
  const startWithNol = phoneNumber.startsWith('0')
  const countryCode = phoneNumber.startsWith('62') || startWithNol ? '62' : ''
  const areaCodePlus = startWithNol ? countryCode.length + 2 : countryCode.length + 3
  const partPlus = startWithNol ? countryCode.length + 6 : countryCode.length + 7

  const areaCode = phoneNumber.slice(startWithNol ? 1 : countryCode.length, areaCodePlus);
  const firstPart = phoneNumber.slice(areaCodePlus, partPlus);
  const secondPart = phoneNumber.slice(partPlus);

  // Create the formatted phone number
  const formattedPhoneNumber = `+${countryCode} ${areaCode}-${firstPart}-${secondPart}`;
  return formattedPhoneNumber;
 }

export default formatPhoneNumber