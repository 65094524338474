import React, { useContext } from 'react'
import InputField from './InputField'
import FormViewContext from './FormViewContext'
import clsx from 'clsx'
import useStyles from './styles'

export function FormFieldGroup (props) {
  const context = useContext(FormViewContext)
  const classes = useStyles()
  
  return (
    <div className={clsx(
        classes.formFieldGroup,
        props.direction === "column" && classes.formFieldGroupColumn,
      )}>
      {React.Children.toArray(props.children)
        .reduce((p, n) =>
          p ? [...p, <div className={classes.formFieldGroupDivider} />, n] : [n]
          , null
        )
      }
    </div>
  )
}

export default FormFieldGroup