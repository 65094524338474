import {
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
  Switch as MuiSwitch,  
} from '@material-ui/core'
import {
  ExpandMore as ExpandIcon,
} from '@material-ui/icons'
import {
  Text,
} from '.'
import useStyles from './styles'

export function Accordion({primary, secondary, switchValue, onSwitchChange, children, ...props}) {
  const classes = useStyles()

  return (
    <MuiAccordion square={true} elevation={0}
      classes={{
        root: classes.accordion,
        expanded: classes.accordionExpanded
      }}
      {...props}
      >
      <MuiAccordionSummary
        expandIcon={<ExpandIcon />}
        classes={{root: classes.accordionSummary}}
        >
        <div className={classes.accordionSummaryFlex}>
          <div className={classes.accordionPrimaryText}>
            <Text>{primary}</Text>  
          </div>
          {secondary &&
            <div className={classes.accordionSecondaryText}>
              <Text variant="body2">{secondary}</Text>          
            </div>
          }
          {switchValue &&
            <div className={classes.accordionSwitch}>
              <MuiSwitch 
                checked={switchValue === "on" ? true : false} 
                color="primary"
                onClick={(event) => event.stopPropagation()}
                onFocus={(event) => event.stopPropagation()}
                onChange={onSwitchChange}
              />
            </div>
          }
        </div>
      </MuiAccordionSummary>
      { children &&
        <MuiAccordionDetails
          classes={{root: classes.accordionDetails}}
          >
          {children}
        </MuiAccordionDetails>
      }
    </MuiAccordion>
  )
}