import axios from 'axios'
import { AUTH_API_URL } from '../../fixtures/apiUrls'

const baseURL = AUTH_API_URL
const http = axios.create({ timeout: 30000, baseURL })

const handleResponse = (res) => {
  if (!res) return { ok: false }

  const { data, status } = res
  const ok = status && status >= 200 && status < 300

  return { ok, status, data }
}

http.interceptors.response.use(
  (response) => handleResponse(response),
  (error) => {
    const { response, config, message } = error
    console.log(response, config, message, error, 'httpInstance:AxiosResponseErrorInterceptor')
    return handleResponse(response)
  }
)

export default http