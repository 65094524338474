import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundColor: '#ececec',
  },
  linearProgress: {
    position: 'fixed',
    height: 2,
    overflow: 'hidden',
    width: '100%',
    zIndex: 1250 // Above Drawer (1200)
  },
  menuPane: {
    backgroundColor: '#efefef',
  },
  menuIcon: {
    width: '5% !important',
    minWidth: '75px !important',
  },
  contentPane: {
    backgroundColor: '#fff',
  },
  listViewCardListInset: {
    marginLeft: theme.spacing(-2),
    marginRight: theme.spacing(-2),
  },  
  listViewTableInset: {
    // marginLeft: theme.spacing(-2),
    // marginRight: theme.spacing(-2),
  },
  listViewFilterBar: {
    marginBottom: theme.spacing(1),
  },
  listViewFilterBarCardInset: {
    // marginLeft: theme.spacing(-2),
    // marginRight: theme.spacing(-2),
  },
  listViewNavigationBar: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: theme.spacing(1),
  },
  listViewNavigationBarItemSpacing: {
    width: theme.spacing(1),
  },
  detailViewTabs: {
    marginTop: theme.spacing(0),
    // marginBottom: theme.spacing(2),
  },
  detailViewSidePanelTabs: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    width: "100%",
    // justifyContent: "center",
    // border: "solid 1px"
  },
  sidebarTabVMargin: {
    // marginTop: theme.spacing(0.5),
    marginTop: theme.spacing(1),
    // marginBottom: theme.spacing(0.5)
  },
  listViewFilterButton: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    button: {
      backgroundColor: 'red'
    }
  }
}))

export default useStyles