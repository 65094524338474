import * as types from '../types'
import createReducer from 'shared/utils/createReducer'
import moment from 'moment'

export const initialState = {
  isFetching: false,
  lastFetched: undefined,
  data: undefined
}

const setFetchingQueues = (state, { isFetching = true }) => ({ ...state, isFetching })
const setQueueList = (state, { data }) => ({
  ...state,
  isFetching: false,
  data,
  lastFetched: new Date()
})

export const actionList = {
  [types.QUEUE_SET_FETCHING_QUEUES]: setFetchingQueues,
  [types.QUEUE_SET_QUEUES]: setQueueList,
}

export const reducer = createReducer(initialState, actionList)
export default reducer