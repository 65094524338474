export default async function processImage(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    //image turned to base64-encoded Data URI.
    reader.name = file.name;//get the image's name
    reader.size = file.size; //get the image's size
    reader.onload = async function (event) {
      const img = document.createElement("img");//create a image
      img.src = event.target.result;//result is base64-encoded Data URI
      img.size = event.target.size;//set size (optional)
      img.onload = async function (el) {
        // only start this if either dimension exceeds 1600
        if (el?.target?.width > 1600 || el?.target?.height > 1600) {
          const elem = document.createElement('canvas');//create a canvas

          //scale the image to 1600 (width) and keep aspect ratio
          if (el.target.width > el.target.height) {
            const scaleFactor = 1600 / el.target.width;
            elem.width = 1600;
            elem.height = el.target.height * scaleFactor;
          } else {
            const scaleFactor = 1600 / el.target.height;
            elem.height = 1600;
            elem.width = el.target.width * scaleFactor;
          }

          //draw in canvas
          const ctx = elem.getContext('2d');
          if (ctx)
            ctx.drawImage(el.target, 0, 0, elem.width, elem.height);

          //get the base64-encoded Data URI from the resize image
          const srcEncoded = ctx.canvas.toDataURL(file?.type, 1);
          const imgRes = await fetch(srcEncoded)
          const imgBlob = await imgRes?.blob()
          const newFile = new File([imgBlob], file?.name, { type: file?.type })
          resolve(newFile)
        } else resolve(file)
      }
    }
    reader.readAsDataURL(file);
  })
}