import * as types from '../types'
import createReducer from 'shared/utils/createReducer'
import moment from 'moment'

export const initialState = {
  isFetching: false,
  lastFetched: undefined,
  data: undefined
}

const setFetchingAvailabilities = (state, { isFetching=true }) => ({ ...state, isFetching})
const setAvailabilities = (state, { data }) => ({ 
  ...state, 
  isFetching: false, 
  data,
  lastFetched: new Date()
})

const receiveAvailabilityUpdate = (state, { data }) => {
  if (state.lastFetched)
    return {
      ...state,
      data: state.data.map(q => {
        if (q.user.uid == data.user.uid)
          return data
        else
          return q
      })
    }
  else
    return state
}

export const actionList = {
  [types.QUEUE_SET_FETCHING_AVAILABILITIES]: setFetchingAvailabilities,
  [types.QUEUE_SET_AVAILABILITIES]: setAvailabilities,
  [types.QUEUE_RECEIVE_AVAILABILITY_UPDATE]: receiveAvailabilityUpdate,
}

export const reducer = createReducer(initialState, actionList)
export default reducer