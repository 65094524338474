import React, { useState, useEffect, useContext, useRef, useMemo } from 'react'
import { useMediaQuery, useTheme } from '@material-ui/core'
import clsx from 'clsx'
import Skeleton from '@material-ui/lab/Skeleton'
import {
  CircularProgress,
} from '@material-ui/core'
import {
  Text
} from '.'
import useStyles from './styles'
import CardList from './CardList'

export const BoardColumn = (props) => {
  const { name, summary, renderFooter, count, cardFields, renderCardItem, loadMoreData, skeleton, ...listProps } = props
  
  const classes = useStyles()
  const theme = useTheme()
  const itemListRef = useRef([])

  const [isFetchingLoadMore, setIsFetchingLoadMore] = useState(false)

  let isFetching = useMemo(() => Boolean(skeleton), [skeleton])
  
  useEffect(() => {
    if(!isFetching) setIsFetchingLoadMore(false)
  }, [isFetching])

  const handleOnScroll = (e) => {
    let load = false
    let topFirstPage = 0

    if(!isFetching && itemListRef.current.length > 0){
      let firstDataPage = listProps?.items?.[listProps?.items?.length - 20]
      let carditem = itemListRef.current[listProps?.items?.length - 20]?.getBoundingClientRect()
      topFirstPage = carditem?.top || 0
    }

    if (!load && !isFetching && topFirstPage < 0) {
      load = true
    } else if (Math.floor(Math.floor(e.target.scrollHeight) - Math.floor(e.target.scrollTop)) === Math.floor(e.target.clientHeight)) {
      // bottom
      load = true
    } else if (Math.floor(Math.floor(e.target.scrollHeight) - Math.floor(e.target.scrollTop)) === Math.floor(e.target.clientHeight) + 1) {
      // bottom
      load = true
    }

    if (load && loadMoreData){
      loadMoreData()
      setIsFetchingLoadMore(true)
    }
  }

  return (
    <div className={clsx(classes.boardColumn)}>
      <div className={classes.boardColumnHeader}>
        {name}
      </div>
      {
        summary &&
        <div className={classes.boardColumnSummary}>
          {summary}
        </div>
      }
      <CardList 
        variant="card"
        {...listProps}
        skeleton={(isFetching && !isFetchingLoadMore) ? skeleton : undefined}
        onScroll={handleOnScroll}
        className={clsx(!renderFooter && classes.boardColumnWrapCard, renderFooter && classes.boardColumnWrapCardWithFooter)}
        fields={cardFields}
        renderCardItem={renderCardItem}
        itemListRef={itemListRef}
      />
      {
        renderFooter &&
        <div className={classes.boardColumnFooter}>
          {renderFooter}
        </div>
      }
    </div>
  )
}

export default BoardColumn