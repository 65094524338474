import {
  List as MuiList,
} from '@material-ui/core'
import useStyles from './styles'

import { Droppable } from "react-beautiful-dnd"

export const List = ({droppableId, ...props}) => {
  const classes = useStyles()

  if (droppableId)
    return (
      <Droppable droppableId={droppableId}>
        {provided => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            <MuiList {...props} />
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    )
  else
    return (
      <MuiList {...props} />
    )
}

export default List