import React, { useState, useEffect } from 'react'
import { CircularProgress } from '@material-ui/core'
import moment from 'moment'

function renderInterval({ start, end }) {
  let diff
  if (start) {
    diff = moment().diff(start, 'milliseconds')
  }
  else if (end) {
    diff = moment(end).diff(moment(), 'milliseconds')
  }
  else
    return ""

  const duration = moment.duration(diff)
  const d = duration.days() > 0 ? `${duration.days()} day(s) ` : ''
  const m = duration.months() > 0 ? `${duration.months()} month(s) ` : ''
  const y = duration.years() > 0 ? `${duration.years()} year(s) ` : ''
  const t = moment.utc(duration.asMilliseconds()).format('HH:mm:ss')

  return `${y}${m}${d}${t}`
}

export const Counter = ({ start, end, style={} }) => {
  const [count, setCount] = useState(renderInterval({ start, end }))

  useEffect(() => {
    const timer = window.setInterval(() => {
      setCount(renderInterval({ start, end }))
    }, 1000)
    return () => {
      window.clearInterval(timer)
    }
  }, [])

  if (count)
    return (
      <div style={style}>
        {count}
      </div>
    )

  return <CircularProgress size={20} />
}

export default Counter
