import {
  makeStyles,
  Tabs as MuiTabs,
} from '@material-ui/core'
import useStyles from './styles'

export const Tabs = ({ children, borderBottom=false,  ...props}) => {
  const classes = useStyles()
  
  return (
    <MuiTabs
      {...props}
      indicatorColor='primary'
      classes={{
        root: borderBottom ? classes.tabsBorderBottom : classes.tabs
      }}
      >
      { children }
    </MuiTabs>
  )
}

export default Tabs