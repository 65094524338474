import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import { DialogForm, Form, FormField } from "shared/ui"
import { useDispatch, useSelect } from 'react-redux'
import {
  STORE_ACTION_START_SHIFT,
  STORE_ACTION_END_SHIFT,
} from '../store/types'

const ShiftForm = ({ open = true, onClose, store, shift, onStart, onEnd, initialData = {}, ...props }) => {
  const [isSaving, setIsSaving] = useState(false)
  const [changedData, setChangedData] = useState({})
  const [errorFields, setErrorFields] = useState({})
  const dispatch = useDispatch()

  useEffect(() => {
    if (!changedData?.channel && initialData.channels?.length > 1)
      onDataChange("channel", initialData.channels[0].uid)
  }, [])

  const onDataChange = (fieldName, value) => {
    let newData = { ...changedData }
    newData[fieldName] = value
    setChangedData(newData)
  }

  const getFieldData = (fieldName, defaultData = null) => {
    if (fieldName in changedData)
      return changedData[fieldName]

    return defaultData
  }

  const closeForm = () => {
    setErrorFields({})
    setChangedData({})
    onClose()
  }

  let actionButtons = [
    {
      kind: 'cancel',
      label: "Cancel",
      onClick: closeForm,
      disabled: isSaving,
    }
  ]

  if (!shift)
    actionButtons.push({
      kind: 'start',
      label: "Start Shift",
      color: "primary",
      variant: "contained",
      onClick: () => {
        setIsSaving(true)
        dispatch({
          type: STORE_ACTION_START_SHIFT,
          startParams: {
            store: { uid: initialData.store.uid },
            ...changedData,
            channel: { uid: changedData.channel },            
          },
          completeCallback: (ok, data) => {
            setIsSaving(false)
            if (ok)
              if (onStart)
                onStart(data)
            else
              alert(JSON.stringify(data, null, 2))
          },
        })              
      },
      disabled: isSaving,
      loading: isSaving,
    })
  else if (shift && !shift.end_time)
    actionButtons.push({
        kind: 'end',
        label: "End Shift",
        color: "primary",
        variant: "contained",
        onClick: () => {
          setIsSaving(true)
          dispatch({
            key: shift.uid,
            type: STORE_ACTION_END_SHIFT,
            endParams: {
              start_time: getFieldData("start_time", initialData.start_time),
              end_time: getFieldData("end_time", initialData.end_time),
              start_note: getFieldData("start_note", initialData.start_note),
              end_note: getFieldData("end_note", initialData.end_note),
            },
            completeCallback: (ok, data) => {
              setIsSaving(false)
              if (ok)
                if (onEnd)
                  onEnd(data)
              else
                alert(JSON.stringify(data, null, 2))
            },
          })              
        },
        disabled: isSaving,
        loading: isSaving,
      })    

  let title = shift ? "Shift Detail" : "Start Shift"
  if (shift)
    title += ` (${shift.store.name})`
  else if (initialData.store)
    title += ` (${initialData.store.name})`

  return (
    <DialogForm title={title} {...{ open, onClose, actionButtons }}>
      <Form
        initialData={initialData}
        errorFields={errorFields}
        changedData={changedData}
        onDataChange={onDataChange}
      >
        { shift ?
          <FormField
            name="channel"
            label="Channel"
            type="option"
            fullWidth={true}
            options={[shift.channel]}
            disabled
            useMargin
          />
        :
          <FormField
            name="channel"
            label="Channel"
            type="option"
            fullWidth={true}
            options={initialData.channels.map(c => ({ ...c, value: c.uid })) || []}
            required
            useMargin
          />
        }
        <FormField
          name="start_time"
          label="Start Time"
          type="datetime"
          fullWidth={true}
          useMargin
        />
        <FormField
          name="end_time"
          label="End Time"
          type="datetime"
          fullWidth={true}
          useMargin
        />
        <FormField
          name="start_note"
          label="Start Note"
          type="textarea"
          fullWidth={true}
          useMargin
        />
        {shift &&
          <FormField
            name="end_note"
            label="End Note"
            type="textarea"
            fullWidth={true}
            useMargin
          />
        }
      </Form>
    </DialogForm>
  )
}

export default ShiftForm