import * as projectList from './projectListReducer'
import * as projectRecord from './projectRecordReducer'
import * as projectWs from './projectWsReducer'

// Data structure
export const WS_STATUS = {
  Idle: "Idle",
  Connecting: "Connecting",
  Connected: "Connected",
  Disconnected: "Disconnected"
}

const initialState = {
  list: projectList.initialState,
  records: {},
  ws: {
    ws_status: WS_STATUS.Idle,
    host: undefined,
  }
}

export const project = (state = initialState, action) => {
  if (Object.keys(projectList.actionList).includes(action.type)) {
    return {
      ...state,
      list: projectList.reducer(state.list, action)
    }
  }
  else if (Object.keys(projectRecord.actionList).includes(action.type)) {
    const records = state.records || {}
    const key = action.key
    return {
      ...state,
      records: {
        ...records,
        [key]: projectRecord.reducer(records[key], action),
      }
    }
  } else if (Object.keys(projectWs.actionList).includes(action.type)) {
    return {
      ...state,
      ws: projectWs.reducer(state.ws, action)
    }
  }

  return state
}