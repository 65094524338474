import {
  Typography as MuiTypography,
  useTheme,
  makeStyles,
} from '@material-ui/core'
import useStyles from './styles'

export const Text = ({ maxLine = 0, ...props }) => {

  const classes = useStyles(props)
  const theme = useTheme()
  let customStyles = {
    wordBreak: "break-word",
  }

  if (props.color == "textPrimaryContrast")
    customStyles['color'] = theme.palette.getContrastText(theme.palette.text.primary)
  else if (props.color == "textSecondaryContrast")
    customStyles['color'] = theme.palette.getContrastText(theme.palette.text.secondary)
  else if (props.color == "textMuted")
    customStyles['color'] = theme.palette.grey.muted

  if (props?.textCustomProps)
    customStyles = { ...customStyles, ...props.textCustomProps }

  // Note: For -webkit-line-clamp, inline style doesn't work. Must use useStyles
  let className = null
  if (maxLine > 0)
    className = classes.limitLineCount

  return (
    <MuiTypography className={className} style={customStyles} {...props} />
  )
}

export default Text