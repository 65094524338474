import ls from 'local-storage'
import { call, put, fork, select, takeLatest, takeEvery } from 'redux-saga/effects'
import * as types from '../types'
import * as api from '../../api'

function* getAvailabilities({}) {
  yield put({ type: types.QUEUE_SET_FETCHING_AVAILABILITIES })
  const { ok, data } = yield call(api.getAvailabilities)
  if (ok)
    yield put({ type: types.QUEUE_SET_AVAILABILITIES, data })    
  else
    yield put({ type: types.QUEUE_SET_FETCHING_AVAILABILITIES, isFetching: false })  
}

function* setAvailability({ key, availability, completeCallback = null }) {
  const { ok, data } = yield call(api.setAvailability, key, availability)
  if (ok) {
    if (completeCallback) completeCallback()
  }
}

function* getQueueList() {
  yield put({ type: types.QUEUE_SET_FETCHING_QUEUES })
  const { ok, data } = yield call(api.getQueueList)
  if (ok)
    yield put({ type: types.QUEUE_SET_QUEUES, data })    
  else
    yield put({ type: types.QUEUE_SET_FETCHING_QUEUES, isFetching: false })  
}

export function* queueSaga() {
  yield takeLatest(types.QUEUE_ACTION_GET_AVAILABILITIES, getAvailabilities)
  yield takeEvery(types.QUEUE_ACTION_SET_AVAILABILITY, setAvailability)
  yield takeLatest(types.QUEUE_ACTION_GET_QUEUES, getQueueList)
}
