import ls from 'local-storage'
import { call, put, fork, select, takeLatest } from 'redux-saga/effects'
import {
  AUTH_ACTION_INITIALIZE,
  AUTH_ACTION_LOGIN,
  AUTH_ACTION_LOGOUT,
  AUTH_ACTION_VERIFY_TOKEN,
  AUTH_SET_INITIALIZED,
  AUTH_SET_USER_INFO,
  AUTH_SET_TOKEN,
  AUTH_SET_AUTHENTICATING,
  AUTH_SET_GOID,
  AUTH_ACTION_INIT_VISITOR,
  AUTH_ACTION_REGISTER_USER
} from '../types'
import {
  ACCOUNT_AUTHORIZE_URL,
  ACCOUNT_LOGOUT_URL,
} from '../../fixtures/apiUrls'
import * as api from '../../api'

const getAuthState = () => select(state => state.auth)

function* initialize() {
  const auth = getAuthState()

  if (auth.isInitialized || auth.isLoggedIn || auth.isInitializing)
    return

  // Try to parse authToken from URL
  let search = window.location.search
  if (search) {
    let searchParams = new URLSearchParams(search)
    let authToken = searchParams.get('auth_token')
    if (authToken) {
      //fork(verifyToken, { token: authToken })
      yield put({ type: AUTH_ACTION_VERIFY_TOKEN, token: authToken })

      searchParams.delete('auth_token')
      searchParams.delete('auth_success')

      let newPath = window.location.pathname
      let search = searchParams.toString()
      if (search)
        newPath += "?" + search

      window.history.replaceState(null, null, newPath)

      yield put({ type: AUTH_SET_INITIALIZED, isInitialized: true })
      return
    }
  }

  // Try to login with last logged in user
  let userInfo = ls.get('_userInfo')
  if (userInfo) {
    // Try to login automatically
    //fork(login, { userUid: userInfo.uid })
    yield put({ type: AUTH_ACTION_LOGIN, userUid: userInfo.uid })

    // Delete local key. If failed, we'll force login
    ls.remove('_userInfo')
    return
  }

  yield put({ type: AUTH_SET_INITIALIZED, isInitialized: true })
}

function* login({ callbackUrl, userUid }) {
  let url = ACCOUNT_AUTHORIZE_URL
  let params = {
    callback: callbackUrl || window.location.href
  }

  // Login as staff
  params["staff"] = 1

  if (userUid)
    params["uid"] = userUid

  url = url + "?" + Object.entries(params).map(kv =>
    kv.map(encodeURIComponent).join("=")).join("&")

  // Redirect to url
  window.open(url, '_self')
}

function* logout({ callbackUrl, userUid }) {
  try {
    let url = ACCOUNT_LOGOUT_URL
    let redirectCallback = callbackUrl || window.location.href
    let paramsLogout = redirectCallback.includes('?') ? 
                        redirectCallback + '&isloggedout=true' : 
                        redirectCallback + '?isloggedout=true'
    let params = {
      callback: paramsLogout
    }

    let goid = ls.get('_goid')
    const { ok, data } = yield call(api.logout, goid)

    if (!ok) {
      console.log('Server side error --->', data);
    }

    // detach action from API call
    // Must be removed to prevent auto login
    ls.remove('_userInfo')

    url = url + "?" + Object.entries(params).map(kv =>
      kv.map(encodeURIComponent).join("=")).join("&")

    // Redirect to url
    window.open(url, '_self')
  } catch (error) {
    console.log('Client side error ---> ', error);
  }
}

function* verifyToken({ token }) {
  yield put({ type: AUTH_SET_AUTHENTICATING, isAuthenticating: true })

  const { ok, data } = yield call(api.verifyToken, token)
  if (ok) {
    yield put({ type: AUTH_SET_TOKEN, token })
    yield put({ type: AUTH_SET_USER_INFO, userInfo: data })

    // Persist userInfo to local storage, but not the token
    ls.set('_userInfo', data)
  }
  else {
    yield put({ type: AUTH_SET_AUTHENTICATING, isAuthenticating: false })
  }
}

function* initVisitor() {
  const auth = yield select(state => state.auth)

  if (!auth.goid) {
    let goid = ls.get('_goid')

    // 12 Aug 2022. Always call init in case _goid already invalid (this happen after we switch between different business)
    if (false) {
    // if (goid) {
      yield put({ type: AUTH_SET_GOID, goid })
    } else {
      const { ok, data } = yield call(api.initGoid, goid ? { _goid: goid } : null)
      if (ok) {
        // Note; init could return different goid if previous goid is invalid or expired
        yield put({ type: AUTH_SET_GOID, goid: data?._goid?.toString() })
        ls.set('_goid', data?._goid?.toString())
      }
    }
  }
}

function* registerUser({ payload }) {
  let body = { ...payload }
  let fcmToken = ls.get('_fcmToken')

  if (fcmToken)
    body = { ...payload, push_token: fcmToken }

  const { ok, data } = yield call(api.registerUser, body)
}

export function* authSaga() {
  yield takeLatest(AUTH_ACTION_INITIALIZE, initialize)
  yield takeLatest(AUTH_ACTION_LOGIN, login)
  yield takeLatest(AUTH_ACTION_LOGOUT, logout)
  yield takeLatest(AUTH_ACTION_VERIFY_TOKEN, verifyToken)
  yield takeLatest(AUTH_ACTION_INIT_VISITOR, initVisitor)
  yield takeLatest(AUTH_ACTION_REGISTER_USER, registerUser)
}
