import clsx from 'clsx'
import {
  Image,
  Button,
  Text,
} from '.'
import {
  useTheme,
  Box,
} from "@material-ui/core"
import AddIcon from "@material-ui/icons/Add"
import useStyles from './styles'

export function ImageInput({value, onUploadImage, allowDelete, onDelete}) {
  const classes = useStyles()
  const theme = useTheme()
  const {image_url, is_uploading, upload_progress} = value || {}


  return (
    <div className={classes.imageInput}>
      {image_url ?
        <div className={classes.imageInputImage}>
          {allowDelete &&
            <div className={classes.imageInputDelete}>
              <Button kind="delete" variant="icon" size="small" onClick={onDelete} />
            </div>
          }
          <img className={classes.imageFitHeight} src={image_url} />
        </div>
      :      
        <div className={classes.imageInputPlaceholder}>
          <input 
            accept="image/*" 
            style={{display: 'none'}} 
            id="perform_upload" 
            type="file"
            onChange={(e) => {
              if (onUploadImage)
                onUploadImage(e.target.files[0])

              // Require to enable input field to accept value again
              e.target.value = ''

              /*
              let fileName = e.target.files ? e.target.files[0].name : ''
              setUploading(true)
              performUpload(fieldName, e.target.files).then(value => {
                if(value !== undefined) {
                  setTimeout(() => {
                    let newValue = {
                      ...value,
                      file_name: fileName
                    }
                    setUploading(false)
                    setTempResult([newValue])
                  }, 500)
                } else {
                  throw new Error("Upload Logo Error");
                }
              })
              */
            }}/>
          <label htmlFor={'perform_upload'}>
            <Box display='flex' flexDirection='column' alignItems='center'>
              <AddIcon style={{ color: theme.palette.primary.main }} />
              <Text variant="body2">Upload Photo</Text>
            </Box>
          </label>
        </div>
      }
    </div>
  )
}

export default ImageInput