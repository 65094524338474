import { useSelector } from 'react-redux'

export { WS_STATUS } from '../reducers/conversationReducer'

export const useStoreList = () => (
  useSelector(state => state.store)
  )

export const useQueueList = () => (
  useSelector(state => state.queueList)
)