export const backgroundImages = [
  { 
    imageUrl: "https://images.unsplash.com/photo-1485199433301-8b7102e86995?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=1600&h=900&fit=crop&ixid=eyJhcHBfaWQiOjF9",
    color: 'rgb(158, 130, 117)',
    color: 'rgb(151, 130, 120)',
    color: 'rgb(191, 170, 160)',
    color: 'rgb(208, 191, 182)',
    color: 'rgb(173, 153, 146)',      
    color: 'rgb(187, 155, 145)',
  },
  { 
    imageUrl: "https://images.unsplash.com/photo-1581252165392-ebc2f68c23de?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=1600&h=900&fit=crop&ixid=eyJhcHBfaWQiOjF9",
    color: 'rgb(59, 101, 122)',
    color: 'rgb(49, 81, 96)',      
    color: 'rgb(77, 105, 117)',            
  },
  { 
    imageUrl: "https://images.unsplash.com/photo-1586521995568-39abaa0c2311?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=1600&h=900&fit=crop&ixid=eyJhcHBfaWQiOjF9",
    color: '#aab0aa',
  },
  {
    imageUrl: "https://images.unsplash.com/photo-1522093007474-d86e9bf7ba6f?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=1600&h=900&fit=crop&ixid=eyJhcHBfaWQiOjF9",
    color: 'rgb(167, 147, 137)',      
    color: 'rgb(208, 191, 182)',
    color: 'rgb(174, 144, 130)', 
    color: 'rgb(158, 126, 111)',
  }
  //"https://images.unsplash.com/photo-1555605976-8bf855afd253?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=1900&h=600&fit=crop&ixid=eyJhcHBfaWQiOjF9"
]

export default backgroundImages