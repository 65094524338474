export const AUTH_ACTION_INITIALIZE = 'AUTH_ACTION_INITIALIZE'
export const AUTH_ACTION_LOGIN = 'AUTH_ACTION_LOGIN'
export const AUTH_ACTION_LOGOUT = 'AUTH_ACTION_LOGOUT'
export const AUTH_ACTION_VERIFY_TOKEN = 'AUTH_ACTION_VERIFY_TOKEN'
export const AUTH_ACTION_INIT_VISITOR = 'AUTH_ACTION_INIT_VISITOR'
export const AUTH_ACTION_REGISTER_USER = 'AUTH_ACTION_REGISTER_USER'
export const AUTH_SET_TOKEN = 'AUTH_SET_TOKEN'
export const AUTH_SET_USER_INFO = 'AUTH_SET_USER_INFO'
export const AUTH_SET_AUTHENTICATING = 'AUTH_SET_AUTHENTICATING'
export const AUTH_SET_INITIALIZED = 'AUTH_SET_INITIALIZED'
export const AUTH_SET_GOID = 'AUTH_SET_GOID'