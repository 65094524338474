import useStyles from './styles'

export const Image = ({ image, src, aspectRatio = 0, loading = 'eager', fitting = 'cover', style }) => {
  const classes = useStyles()
  const imageSrc = src || (image && image.image_url)

  // PLACEHOLDER: currently uses solid color from generator
  // later it has to be provided locally from our internal.

  if (aspectRatio != 0) {
    return (
      <div style={{
        width: '100%',
        height: 'auto',
        position: 'relative',
        paddingBottom: `${100 / aspectRatio}%`
      }}>
        <div style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
          overflow: 'hidden',
          // This is most reliable solution to fill image in the box
          backgroundImage: `url(${imageSrc || 'https://singlecolorimage.com/get/d1d1d1/100x100'})`,
          backgroundSize: fitting,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          ...style
        }}>
          {false && imageSrc &&
            <img style={{
              objectFit: 'cover',
              width: '100%',
              height: '100%'
            }}
              src={imageSrc || 'https://singlecolorimage.com/get/d1d1d1/100x100'}
              loading={loading}
              alt={`source: ${imageSrc}`}
            />
          }
        </div>
      </div>
    )
  }

  return <img src={imageSrc || 'https://singlecolorimage.com/get/d1d1d1/100x100'} className={classes.image} loading={loading} alt={`source: ${imageSrc}`} />

  return (
    <div className={classes.image}>
      {imageSrc &&
        <img src={imageSrc} style={{
          maxWidth: '100%',
          minWidth: '100%',
        }}
        />
      }
    </div>
  )
}

export default Image