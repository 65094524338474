import { useSelector } from 'react-redux'

export { WS_STATUS } from '../reducers/projectReducer'

export const useProjectWSStatus = () => (
  useSelector(state => state.project.ws.ws_status)
)

export const useProjectList = () => (
  useSelector(state => state.project.list || {})
)

export const useProjectDetail = (key) => (
  useSelector(state => state.project.records[key] || {})
)
