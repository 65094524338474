import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      minWidth: '280px !important',
    },
    width: '280px'
  },
  notificationCard: {
    backgroundColor: 'white',
    boxShadow: theme.shadows[2],
    borderRadius: theme.shape.borderRadius,
    overflow: "hidden",
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    width: '100%',
  },
  notificationCardBody: {
    display: '-webkit-box',
    overflow: 'hidden',
    '-webkit-line-clamp': 4,
    ' -webkit-box-orient': 'vertical',
  }
}))

export default useStyles
