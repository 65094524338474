import { get, post } from 'api/request'

export const API_QUEUE = "/conversation/service/queue"

export const getQueueList = () => get(`${API_QUEUE}`)
export const getAvailabilities = () => get(`${API_QUEUE}/availabilities`)
export const setAvailability = (key, availability) => post(
  `${API_QUEUE}/${key}/set_availability`,
  { availability }    
)
