import { call, put, select, takeLatest } from 'redux-saga/effects'
import * as types from '../types'
import * as api from '../../api'
  
function* getProjectList({loadMoreData=false, filters={}, order = "" }) {
  const projectList = yield select(state => state.project.list)
  let offset = 0
  let size = 20
  if (loadMoreData) {
    if (projectList.lastFetched && projectList.data.total <= (projectList.data.offset + projectList.data.size))
      // No more to load
      return
  }
  if (loadMoreData)
    offset = projectList.data.offset + projectList.data.size

  yield put({ type: types.PROJECT_SET_FETCHING_PROJECT_LIST })

  const { ok, data } = yield call(api.getProjectList, offset, size, filters, order)
  if (ok)
    yield put({ type: types.PROJECT_SET_PROJECT_LIST, data, append: loadMoreData })    
  else
    yield put({ type: types.PROJECT_SET_FETCHING_PROJECT_LIST, isFetching: false })  
}

function* getProjectDetail({ key }) {
  yield put({ type: types.PROJECT_SET_FETCHING_PROJECT_DETAIL, key })

  const { ok, data } = yield call(api.getProjectDetail, key)
  if (ok)
    yield put({ type: types.PROJECT_SET_PROJECT_DETAIL, key, data })    
  else
    yield put({ type: types.PROJECT_SET_FETCHING_PROJECT_DETAIL, key, isFetching: false })  
}

export function* projectSaga() {
  yield takeLatest(types.PROJECT_ACTION_GET_PROJECT_LIST, getProjectList)
  yield takeLatest(types.PROJECT_ACTION_GET_PROJECT_DETAIL, getProjectDetail)
}
