import { useContext } from 'react'
import InputField from './InputField'
import FormViewContext from './FormViewContext'
import useStyles from './styles'

export function FormField (props) {
  const context = useContext(FormViewContext)
  const classes = useStyles()
  
  return (
    <div className={classes.formField}>  
      <InputField 
        onChange={context.onFieldChange && context.onFieldChange(props.name)}
        value={context.getFieldValue && context.getFieldValue(props.name)}
        error={context.getFieldError && context.getFieldError(props.name)}
        {...props} 
        />
    </div>
  )
}

export default FormField