import {
  Avatar as MuiAvatar,
  Box as MuiBox,
  useTheme,
} from '@material-ui/core'
import useStyles from './styles'

export const Avatar = ({size, children}) => {
  const classes = useStyles()
  const theme = useTheme()
  
  return (
    <MuiAvatar style={{ width: theme.spacing(size), height: theme.spacing(size) }}>
      {children}
    </MuiAvatar>
  )
}

export default Avatar