import React, { useState, useContext } from 'react'
import clsx from 'clsx'
import {
  Typography as MuiTypography,
  useMediaQuery,
  useTheme,
  Button as MuiButton,
  Dialog as MuiDialog
} from '@material-ui/core'
import SplitViewPane from './SplitViewPane'
import {
  Tabs,
  Tab,
  ViewContext,
} from '.'
import useStyles from './styles'

export const SplitView = (props) => {    
  const classes = useStyles()
  const {activePane, onSetActivePane} = props
  const {divider="line", showTabs=false} = props
  
  const panes = React.Children.toArray(props.children).filter(e => React.isValidElement(e) && e.type == SplitViewPane)

  const {topInset=0, bottomInset=0} = props
  const {fixedContentPane=false} = props

  const { classNameContentPane } = props

  const viewContext = useContext(ViewContext)  
  
  const theme = useTheme()
  const smDown = useMediaQuery(theme.breakpoints.down("sm")) || viewContext.isSidebarView

  const {fixedPosition=false} = props
  let fixedStyle = {}
  if (fixedPosition) {    
    fixedStyle = {
      position: "absolute",
      overflowX: "hidden",
      overflowY: "auto",
      width: "100%",
    }
    if (viewContext.containerSize.height > 0)
      fixedStyle['height'] = viewContext.containerSize.height
    else if (viewContext.containerInsets.top > 0)
      fixedStyle['height'] = `calc(100%-${viewContext.containerInsets.top}px)`
    else
      fixedStyle['height'] = '100%'
  }

  if (smDown) {
    return (
      <div className={classes.splitViewMobile}>
        {showTabs &&
          <Tabs value={activePane}>
            {panes.map((pane, index) =>
              <Tab 
                label={pane.props.title}
                onClick={() => onSetActivePane(index)}
              />
            )}
          </Tabs>
        }
        {activePane <= panes.length &&
          <div className={classes.splitViewMobilePane}>
            {panes[activePane]}
          </div>
        }
      </div>
    )
  }
  else {
    const paneList = panes.map(pane => {
      const props = pane.props
      if (props.pane == "side")      
        return (
          <div className={clsx(
              classes.splitViewSidePane, 
              props.collapsed && classes.splitViewSidePaneCollapsed,
              !props.collapsed && props.width == "icon" && classes.splitViewIconSidePane,
              !props.collapsed && props.width == "small" && classes.splitViewSmallSidePane,
              !props.collapsed && props.width == "large" && classes.splitViewLargeSidePane,
              props.hidden && classes.splitViewPaneHidden,
            )}>
            {false && showTabs && 
              <div className={classes.splitViewPaneTitle}>
                {props.title}
              </div>
            }
            <div className={classes.splitViewSidePaneContainer}>            
              {pane}
            </div>
          </div>
        )
      else
        return (
          <div className={clsx(classes.splitViewContentPane, classNameContentPane)}> 
            {false && showTabs && 
              <div className={classes.splitViewPaneTitle}>
                {props.title}
              </div>
            }
            <div className={classes.splitViewContentPaneContainer}>
              {pane}
            </div>
          </div>
        )      
    })
    
    let dividerEl = null
    const isDividerLine = divider === "line"
    if (isDividerLine)
      dividerEl = <div className={classes.splitViewDividerLine} />
    else if (divider === "space")
      dividerEl = <div className={classes.splitViewDividerSpace} />
    else if (Array.isArray(divider) || React.isValidElement(divider))
      dividerEl = divider
    
    return (
      <div className={classes.splitView}
        style={fixedStyle}
        >
        {dividerEl ?
          paneList.reduce((p, n) => p ?
            isDividerLine ? [p, dividerEl, n, dividerEl] : [p, dividerEl, n] 
            : [n],
            null)
        :
          paneList
        }
      </div>    
    )    
  }

/*
  // This doesn't work on mobile where we could have different window size
  // on scrolling
  let listPaneContainerHeight = '100vh'
  if (topInset > 0 || bottomInset > 0)
    listPaneContainerHeight = `calc(100vh - ${topInset-bottomInset}px)`
*/

/*
  const { width, height } = useWindowSize()
  let containerHeight = (height - (topInset-bottomInset)) + 'px'
  
  return (
    <div className={classes.splitView}>
      { leftPaneContent &&
      <div className={classes.leftPane} style={leftPaneStyle}>
        <ResizeDetector
          handleWidth
          render={({ width }) => (
            <div className={classes.leftPaneContainer}
              style={{ 
                  width: width, 
                  height: containerHeight,
                  top: topInset,
                  position: 'static',
                  height: '100%',
                  top: 0,
                }}
              >
              { leftPaneContent }
            </div>
          )}
        />
      </div>          
      }

      <div className={classes.contentPane}>
      { fixedContentPane ?
        <ResizeDetector
          handleWidth
          render={({ width }) => (
            <div className={classes.contentPaneContainer}
              style={{ 
                  position: 'fixed',
                  overflow: 'scroll',
                  width: width, 
                  height: containerHeight,
                  top: topInset,
                  position: 'static',
                  width: 'auto',
                  height: '100%',
                  top: 0
                }}
              >
              { children }
            </div>
          )}
        />            
        :
        <div className={classes.contentPaneContainer}>
          { children }
        </div>
        }
      </div>

      { rightPaneContent &&
        <div className={classes.rightPane} style={rightPaneStyle}>
          <ResizeDetector
            handleWidth
            render={({ width }) => (
              <div className={classes.rightPaneContainer}
                style={{ 
                    width: width, 
                    height: containerHeight,
                    top: topInset,
                    width: 'auto',
                    position: 'static',
                    height: '100%',
                    top: 0                  
                  }}
                >
                { rightPaneContent }
              </div>
            )}
          />    
        </div>
      }
    </div>
  )
  */
}


export default SplitView