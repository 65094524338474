import { useContext } from "react"
import Dialog from './Dialog'
import FormViewContext from './FormViewContext'
import useStyles from './styles'

export function DialogForm({ children, initialData={}, changedData, errorFields, onDataChange, ...props }) {
  const classes = useStyles()
  const formViewContext = useContext(FormViewContext)

  const hasChanged = Boolean(changedData && Object.keys(changedData).length > 0)  

  const onFieldChange = (fieldName) => (value) => {
    if (value == getFieldValue(fieldName))
      return

    const fieldNames = fieldName.split(".")
    const key = fieldNames[0]
    const subKey = fieldNames.length > 1 && fieldNames[1]
    
    if (subKey)
      onDataChange(key, {
        ...(changedData[key] || initialData[key] || {}),
        [subKey]: value
      })
    else    
      onDataChange(fieldName, value)
  }
  
  const getFieldValue = (fieldName) => {
    const fieldNames = fieldName.split(".")
    const key = fieldNames[0]
    const subKey = fieldNames.length > 1 && fieldNames[1]
    if (changedData && key in changedData)
      return subKey ? changedData[key][subKey] : changedData[key]
    if (initialData && key in initialData)
      return subKey ? initialData[key][subKey] : initialData[key]
    return null
  }
  
  const getFieldError = (fieldName) => {
    return errorFields && errorFields[fieldName]
  }
  
  return (
    <Dialog {...props}>
      <FormViewContext.Provider value={{
          onFieldChange,
          getFieldValue,
          getFieldError,
        }}>
        { children }
      </FormViewContext.Provider>
    </Dialog>
  )
}

export default DialogForm