import {
  Chat,
  Devices,
  Receipt,
  Activity,
  Group,
  PhoneInTalk,
  Mail
} from '@material-ui/icons'

export const activityTypes = {
  'chat-with-operator': { name: "Conversation with Operator", icon: Chat },
  'chat-with-agent': { name: "Conversation with Agent", icon: Chat },
  'conversation-with-agent': { name: "Conversation with Agent", icon: Chat },
  'conversation-with-operator': { name: "Conversation with Agent", icon: Chat },
  'conversation': { name: "Conversation with Agent", icon: Chat },
  'chat': { name: "Chat with Agent", icon: Chat },
  'phone-call': { name: "Chat with Agent", icon: PhoneInTalk },
  'app-session': { name: "App Session", icon: Devices },
  'order-placed': { name: "Order Placed", icon: Receipt },
  'meeting': { name: "Meeting", icon: Group },
  'email': { name: "Email", icon: Mail }
}

export default activityTypes