import React, { forwardRef } from 'react'
import { useHistory } from 'react-router-dom'
import { SnackbarContent } from 'notistack'
import { Text } from 'shared/ui'
import useStyles from './styles'

const ProjectNotification = forwardRef((props, ref) => {
  const { msg, body, data } = props
  const history = useHistory()
  const classes = useStyles()

  const getSenderName = () => {
    const sender = data?.created_by
    if (sender) {
      let name = sender?.first_name || ''
      if (sender?.last_name)
        name += " " + sender?.last_name || ''
      if (!name || name.length <= 0)
        name = data?.sender?.address || `Contact ${sender?.uid % 9999}`
      return name
    } else return "Unknown"
  }

  const NavigateToIssue = () => {
    const issueData = data?.issue || data
    history.push(`/service/project/${issueData?.project?.key}/view/${issueData?.uid}`)
  }

  return (
    <SnackbarContent ref={ref} className={classes.root}>
      <div className={classes.notificationCard} onClick={NavigateToIssue}>
        <Text style={{ fontWeight: 700 }}>
          {msg}
        </Text>
        <Text
          variant="body2"
          color="textSecondary"
          className={classes.notificationCardBody}
        >
          {body ||
            <>
            {getSenderName()}: {data?.comment}
            </>
          }
        </Text>
      </div>
    </SnackbarContent>
  )
})

export default ProjectNotification