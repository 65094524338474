import React, { useState, useEffect, useContext } from 'react'
import useStyles from './styles'
import {
  FormView,
  ViewContext,
  Button,
  Skeleton,
  Tab,
  Tabs
} from "shared/ui"
import { Badge as MuiBadge, Box as MuiBox } from '@material-ui/core'
import clsx from 'clsx'

function SidebarController(props) {
  const { isNew, isFetching, lastFetched, data } = props
  const { isSaving } = props
  const viewContext = useContext(ViewContext)
  const classes = useStyles()

  // Editing feature
  const { sidePanels } = props
  const { sidePanelTabs, selectedSidePanelTab, onSelectedSidePanelTabChange } = props
  const { editMode = "none", changedData, onDataChange, errorFields, onSave, onReset } = props
  const hasChanged = Boolean(isNew || changedData && Object.keys(changedData).length > 0)
  const { primaryButton, secondaryButtons } = props

  useEffect(() => {
    viewContext.setLoading(isFetching)
    return () => {
      viewContext.setLoading(false)
    }
  }, [isFetching])

  let actionButtons = []
  if (editMode == "always" || (editMode == "auto" && hasChanged)) {
    // Add Save and Cancel button
    actionButtons.push({
      color: "primary",
      variant: "contained",
      kind: "save",
      onClick: onSave,
      label: "Save",
      loading: isSaving,
      disabled: !hasChanged,
    })
    actionButtons.push({
      kind: "cancel",
      onClick: onReset,
      label: "Cancel",
    })
  }
  else {
    if (primaryButton) {
      actionButtons.push({
        variant: "contained",
        color: "primary",
        ...primaryButton,
      })
    }

    if (secondaryButtons)
      actionButtons = [...actionButtons, ...secondaryButtons]
  }

  if (actionButtons.length > 0 && actionButtons[actionButtons.length - 1].variant !== "contained")
    actionButtons[actionButtons.length - 1].insetRightMargin = true

  const renderTabIcon = (icon, count) => (
    <MuiBadge badgeContent={count} color="primary">
      {React.isValidElement(icon) ? icon : React.createElement(icon)}
    </MuiBadge>
  )

  function renderSidePanelTabs() {
    if (!sidePanelTabs)
      return

    return (
      <div className={clsx(classes.detailViewSidePanelTabs, classes.sidebarTabVMargin)}>
        {/* {sidePanelTabs.map((t, index) =>
          <Button {...t}
            color={index == selectedSidePanelTab ? "primary" : ""}
            insetRightMargin={index == sidePanelTabs.length - 1}
            onClick={() => onSelectedSidePanelTabChange(index)}
          />
        )} */}
        <Tabs value={selectedSidePanelTab} variant="fullWidth" style={{ width: "100%" }}>
          {sidePanelTabs.map((t, index) => (
            <Tab
              icon={renderTabIcon(t?.icon, t?.badge)}
              value={index}
              onClick={() => onSelectedSidePanelTabChange(index)}
              noMargin
            />
          ))}
        </Tabs>
      </div>
    )
  }

  return (
    <FormView title={props.title}
      headerProps={{
        useColumnLayout: true,
        sideContent: renderSidePanelTabs(),
        ...(props.headerProps || {}),
      }}
      extraBreadcrumbsContent={props.extraBreadcrumbsContent}
      footerProps={props.footerProps}
      actionButtons={actionButtons}
      initialData={isNew ? {} : lastFetched && data || {}}
      changedData={changedData}
      onDataChange={onDataChange}
      errorFields={errorFields}
      variant={props.viewVariant}
    >
      <div>
        {sidePanels}
      </div>
      {props.children}
    </FormView>
  )
}

export default SidebarController