function fixUrlSuffix(url) {
  // Make sure url has suffix "/" as required by our backend
  const i = url.indexOf('?')
  if (i == -1) {
    if (url[url.length-1] != '/')
      return url + "/"
  }
  else {
    if (url[i-1] != '/')
      return url.substr(0, i) + "/" + url.substr(i)
  }
  
  return url
}

export default fixUrlSuffix