import React, { useContext, useEffect, useState, useRef } from 'react'
import { useMediaQuery, useTheme } from '@material-ui/core'
import clsx from 'clsx'
import {
  Header,
  Text,
  Button,
  ViewContext,
} from '.'
import {
  Box as MuiBox,
} from '@material-ui/core'
import useElementScroll from "../utils/useElementScroll"
import useStyles from './styles'

import ResizeDetector, { withResizeDetector } from 'react-resize-detector'

export const View = (props) => {
  const { actionButtons = [] } = props
  const { title } = props
  const { variant } = props
  const { extraBreadcrumbsContent } = props
  const headerProps = props.headerProps ? { title, ...props.headerProps } : { title }
  const footerProps = props.footerProps || { content: null }
  const viewContext = useContext(ViewContext)
  const {
    showPageMenu = Boolean(viewContext.breadcrumbs || actionButtons),
    showTitle = Boolean(title || headerProps),
    useHeader = true,
    headerStyleProps = {},
    headerRef = null
  } = props

  const classes = useStyles()
  const theme = useTheme()
  const smDown = useMediaQuery(theme.breakpoints.down("sm")) || viewContext?.isSidebarView

  // Can not use this. Noticably slow
  // const [divRef, [scrollX, scrollY]] = useElementScroll()
  // let headerStyle = { 
  //   transform: `translate(0px, ${scrollY}px)`, 
  //   backgroundColor: "white",
  //   zIndex: 1
  // }

  // Fastest way, using sticky position, but must check browser compatibility
  let headerStyle = {...{
    position: "sticky",
    top: 0,
    zIndex: 1,
  }, ...headerStyleProps}
  let footerStyle = {
    position: "sticky",
    bottom: 0,
    zIndex: 1,
  }

  if (showTitle || showPageMenu)
    headerStyle["backgroundColor"] = "white"
  if (footerProps.content)
    footerStyle["backgroundColor"] = "white"
  if(footerProps?.content?.props?.children == null)
    footerStyle["paddingBottom"] = 0
  if (footerProps.backgroundColor)
    footerStyle["backgroundColor"] = footerProps.backgroundColor

  const [headerHeight, setHeaderHeight] = useState([0, 0])
  const [footerHeight, setFooterHeight] = useState([0, 0])

  const { fixedPosition = false, onScroll, isInModal = false } = props
  let fixedStyle = {}
  if (fixedPosition) {
    fixedStyle = {
      position: isInModal ? "relative" : "absolute",
      overflowX: "hidden",
      overflowY: "auto",
      width: "100%",
    }
    if (viewContext.containerSize.height > 0)
      fixedStyle['height'] = isInModal ? (viewContext.containerSize.height * 0.75) : viewContext.containerSize.height
    else if (viewContext.containerInsets.top > 0)
      fixedStyle['height'] = `calc(100%-${viewContext.containerInsets.top}px)`
    else
      fixedStyle['height'] = '100%'
  }

  // This require getting view width using resize detector
  // and also have to get header and footer height
  // which cause lots of rerender.
  // if (false) {
  //   headerStyle = {
  //     ...headerStyle,
  //     position: "fixed",
  //     top: viewContext.containerInsets.top,
  //     width: props.width,
  //     backgroundColor: "white",
  //     zIndex: 1,
  //   }
  //   footerStyle = {
  //     position: "fixed",
  //     bottom: viewContext.containerInsets.bottom,
  //     width: props.width,
  //     backgroundColor: "white",
  //     zIndex: 1,
  //   }
  // }

  function renderHeader() {
    return (
      <div className={clsx(
        classes.viewContent,
        classes.viewHeader,
        viewContext.isSidebarView && classes.viewContentSideBar,
        variant === "dimmed" && classes.viewContentDimmed,
        variant === "flushed" && classes.viewContentFlushed
      )}
        style={headerStyle}
        ref={headerRef || undefined}
      >
        {showPageMenu &&
          <div className={classes.viewPageMenu}>
            <div className={classes.viewPageBreadcrumbs}>
              {viewContext.breadcrumbs && viewContext.breadcrumbs.map((b, index) =>
                <Button
                  color="primary"
                  {...b}
                />
              ).reduce((p, n) => p ?
                [...p, <MuiBox ml={1} />, n] : [n]
                , null)
              }
            </div>
            <div className={classes.viewPageBreadcrumbsExtraContent}>
              {extraBreadcrumbsContent}
            </div>
            {actionButtons &&
              <div 
                className={clsx(
                  classes.viewPageActionButtons, 
                  viewContext.isSidebarView && classes.viewPageActionButtonsSidebar,
                  variant === "flushed" && classes.actionButtonPadding,
                  actionButtons.some(some => some.kind == "back" || some?.leftButton) && classes.actionWithBack
                )}>
                  {actionButtons?.filter(item => item.kind == "back" || item?.leftButton).map(b =>
                      <Button {...b} />
                    ).reduce((p, n) => p ?
                      [...p, <MuiBox ml={1} />, n] : [n]
                      , null)
                    }
                  <div style={{display: 'flex', alignItems: 'center'}}>
                    {actionButtons?.filter(item => item.kind !== "back" && !item?.leftButton).map(b =>
                      <Button {...b} />
                    ).reduce((p, n) => p ?
                      [...p, <MuiBox ml={1} />, n] : [n]
                      , null)
                    }
                  </div>
              </div>
            }
          </div>
        }
        {showTitle &&
          <div className={classes.viewPageTitle}>
            <Header {...headerProps} />
          </div>
        }
        {showPageMenu && showTitle && !smDown &&
          <div className={classes.viewHeaderGutter} />
        }
      </div>
    )
  }

  function renderFooter() {
    return (
      <div className={clsx(
        classes.viewContent,
        classes.viewFooter,
        viewContext.isSidebarView && classes.viewContentSideBar,
        variant === "dimmed" && classes.viewContentDimmed,
        smDown && classes.viewFooterMobile
      )}
        style={footerStyle}
      >
        {footerProps.content}
      </div>
    )
  }

  return (
    <div className={classes.view}
      style={fixedStyle}
      ref={null}
      {...{ onScroll }}
    >
      {useHeader && (
        <>
          {renderHeader()}
          <div style={{ height: headerHeight }} />
        </>
      )}

      <div className={clsx(
        classes.viewContent,
        variant === "onlyIcon" && classes.viewContentOnlyIcon,
        variant === "dimmed" && classes.viewContentDimmed,
        variant === "flushed" && classes.viewContentFlushed,
        viewContext.isSidebarView && classes.viewContentSideBar
      )}>
        {props.children}
      </div>

      <div style={{ height: footerHeight }} />
      {renderFooter()}
    </div>
  )

  // return (
  //   <div className={classes.view}
  //     ref={null}
  //     >
  //     <ResizeDetector handleHeight>
  //       {({ height }) => {
  //         if (height > 0 && height != headerHeight) {
  //           setHeaderHeight(height)
  //         }
  //         return renderHeader()
  //       }}
  //     </ResizeDetector>
  //     <div style={{height: headerHeight + 8*4}} />
  //     <div className={classes.viewContent}>      
  //       {props.children}
  //     </div>
  //     <div style={{height: footerHeight}} />
  //     <ResizeDetector handleHeight>
  //       {({ height }) => {
  //         if (height > 0 && height != footerHeight)
  //           setFooterHeight(height)
  //         return renderFooter()
  //       }}
  //     </ResizeDetector>
  //   </div>
  // )
}

export default View
// export default withResizeDetector(View)