import {
  AUTH_SET_INITIALIZED,
  AUTH_SET_AUTHENTICATING,
  AUTH_SET_TOKEN,
  AUTH_SET_USER_INFO,
  AUTH_SET_GOID
} from '../types'

import createReducer from '../../shared/utils/createReducer'

const initialState = {
  isInitialized: false,
  token: undefined,
  userInfo: undefined,
  isAuthenticating: false,
  isLoggedIn: false,
  error: undefined
}

const setInitialized = (state, { isInitialized=true }) => ({ ...state, isInitialized})
const setAuthenticating = (state, { isAuthenticating=true }) => ({ ...state, isAuthenticating})
const setToken = (state, { token }) => ({ ...state, token })
const setUserInfo = (state, { userInfo }) => {
  if (userInfo)
    return {
      ...state,
      userInfo,
      isAuthenticating: false,
      isLoggedIn: true
    }
  else
    return {
      ...state, 
      userInfo, 
      isAuthenticating: false 
    }
}

const setGoid = (state, { goid }) => ({ ...state, goid })

export const reducer = createReducer(initialState, {
  [AUTH_SET_INITIALIZED]: setInitialized,
  [AUTH_SET_TOKEN]: setToken,
  [AUTH_SET_USER_INFO]: setUserInfo,
  [AUTH_SET_AUTHENTICATING]: setAuthenticating,
  [AUTH_SET_GOID]: setGoid
})

export default reducer