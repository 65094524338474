import http from './http'
import fixUrlSuffix from '../../shared/utils/fixUrlSuffix'
import lo from 'lodash'
import { API_KEY } from '../../fixtures/apiUrls'

export const request = (config) => {
  // Make sure url has suffix "/" as required by our backend
  config = { ...config, url: fixUrlSuffix(config.url)}
  
  // 4 Oct 2021. We now use API Key
  config.headers = { ...config.headers, "X-API-Key": API_KEY }  
  
  //alert(config.method + " " + config.url)
  
  return http.request(lo.pick(config, ['url', 'method', 'data', 'headers']))
}

export const post = async (url, body, opts={}) => {
  const options = {
    method: 'post',
    url: url,
    data: body,
    headers: opts.headers,
  }
  
  return request(options)
}
