import React from 'react'
import { FileListView, Text } from "shared/ui"
import { Chip, Box as MuiBox } from "@material-ui/core"
import getStyleProgressStatus from "./getStyleProgressStatus"
import moment from "moment"
import formatValue from "./formatValue"
import {
   DirectionsRun as ActivityIcon,
 } from '@material-ui/icons'
import activityTypes from "fixtures/activityTypes"
import useStyles from './styles'
import { useAppContext } from 'App'

const RenderFieldComponent = ({item, field}) => {
   const classes = useStyles()

   if (field.component == 'ActivityInfo') {
      const activityInfoList = [
         {
            key: 'activities_count',
            icon: ActivityIcon
         },
         {
            key: 'comments_count',
            icon: (activityTypes['chat'] || {}).icon
         }
      ]

      return (
         <div style={{ display: 'flex', flexDirection: 'row' }}>
            {
               activityInfoList
                  // ?.filter(f => typeof item[f.key] !== 'undefined')
                  ?.filter(f => item[f.key])
                  ?.map(act => (
                     <div className={classes.activityItem}>
                        {React.createElement(
                           act.icon, {
                           style: { color: '#aaa', fontSize: '15px', marginRight: '2px' }
                        }
                        )}
                        <Text variant="subtitle2">
                           {item[act.key]}
                        </Text>
                     </div>
                  ))
            }
         </div>
      )
   }

   return ''
}

const getUserName = (user) => {
   if (!user) return ''

   let name = ''
   if (user) {
      if (user?.first_name || user?.last_name) {
         name = `${user?.first_name || ""} ${user?.last_name || ""}`.trim()
      }
   }

   if (!name) {
      name = `Contact ${user?.uid % 9999}`
   }

   return name
}

const RenderFieldUser = ({item, field}) => {
   const appContext = useAppContext()
   let fieldValue = null

   if (item[field.key]) {
      if (item[field.key].uid == appContext.userInfo?.uid)
         fieldValue = "Me"
      else
         fieldValue = `${item[field.key].first_name || ''} ${item[field.key].last_name || ''}`
   } else {
      // Assigned to all agent
      fieldValue = "Unassigned"
   }

   return fieldValue
}

const FormatFieldValue = (item, field) => {

   let fieldValue = null

   if (!field)
      return null
   else if (field.render)
      fieldValue = field.render(item, field)
   else if (field.component)
      fieldValue = <RenderFieldComponent {...{item, field}}/>
   else if (field.field_type == 'contact')
      fieldValue = getUserName(item[field.key])
   else if (field.field_type == 'company')
      fieldValue = item[field.key]?.name
   else if (field?.field_type == 'company_contact')
      fieldValue = `${getUserName(item[field.key]?.contact)} ${item[field.key]?.company ? `from ${item[field.key]?.company?.name}` : ''}`
   else if (field.field_type == 'option') {
      if (field?.multiple_value) {
         fieldValue = item[field.key]?.map(field => <Chip label={field.name} style={{ margin: '3px 5px 3px 0' }} />)
      } else fieldValue = item[field.key]?.name
   }
   else if (field.field_type == 'custom' && typeof item?.[field.key] == 'object') {
      fieldValue = item?.[field.key]?.name || item?.[field.key]?.title || item?.[field.key]?.value || ''
   }
   else if (field.field_type == 'user') {
      fieldValue = <RenderFieldUser {...{item, field}} />
   }
   else if (field.field_type == 'status') {
      fieldValue = (
         <div style={{ display: 'flex' }}>
            <MuiBox>
               <Text
                  variant="body2"
                  noWrap={true}
                  textCustomProps={getStyleProgressStatus(item[field.key])}
               >
                  {item[field.key]?.name}
               </Text>
            </MuiBox>
         </div>
      )
   }
   else if (field.field_type == 'float')
      fieldValue = item[field.key] ? new Intl.NumberFormat('ID').format(item[field.key]) : ''
   else if (
      field.field_type == 'date' ||
      (moment(item[field.key], moment.ISO_8601, true).isValid() && !field.format) // check value is date without format
   )
      fieldValue = formatValue(item[field.key], 'datetime|DD MMM yyy')
   else if (field.field_type == 'media')
      fieldValue = item[field.key] ? (
         <FileListView value={item[field.key]} useSmallThumbs disableBorder />
      ) : <></>
   else fieldValue = formatValue(item[field.key], field.format)

   return (field?.label && fieldValue) ? (
      <div>
         <Text variant="body2">{field.label}: {fieldValue}</Text>
      </div>
   ) : fieldValue
}

export default FormatFieldValue