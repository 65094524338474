import { useState, useContext } from 'react'
import useStyles from './styles'
import { SplitView, SplitViewPane } from 'shared/ui'
import { useMediaQuery, useTheme, Button } from "@material-ui/core"

function MainViewContactController(props) {
  const [showContactPane, setShowContactPane] = useState(false)
  const [activePane, setActivePane] = useState(0)

  const classes = useStyles()
  const theme = useTheme()
  const smDown = useMediaQuery(theme.breakpoints.down("sm"))

  return (
    <SplitView
      activePane={activePane}
      onSetActivePane={setActivePane}
      fixedPosition={true}
    >
      {/* MAIN PANE CONTAINS ROUTE CHILDRES */}
      <SplitViewPane pane="content" fixedPosition={true}>
        {props.children}
      </SplitViewPane>

      {/* CONTACT PANE SHOWS CONTACT INFO */}
      {showContactPane && (
        <SplitViewPane pane="side" width="large">
          tes
        </SplitViewPane>
      )}
    </SplitView>
  )
}

export default MainViewContactController
