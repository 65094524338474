import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import { SnackbarProvider } from 'notistack'
import { ThemeProvider } from '@material-ui/styles'
import theme from './fixtures/theme'

import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'

//import configureStore from './store/configureStore'
import store from './store'

// firebase
import { initializeApp } from 'firebase/app'
import { getMessaging, getToken } from 'firebase/messaging'

//const { store } = configureStore()

const firebaseConfig = process.env.REACT_APP_GOAPP_FIREBASE_CONFIG
console.log('firebase config ---> ', firebaseConfig);

if (firebaseConfig) {
  initializeApp(JSON.parse(firebaseConfig))
}

if ('serviceWorker' in navigator && firebaseConfig) {
  const firebaseConfigParams = new URLSearchParams(JSON.parse(firebaseConfig))
  navigator.serviceWorker.register(`${process.env.PUBLIC_URL}/firebase-messaging-sw.js?${firebaseConfigParams}`)
    .then(function (registration) {
      getToken(getMessaging(), { 
        vapidKey: 'BAMSIiijs9V_euCneshqzfTIpLOTeKnI2jStz0IDem0-hb2qRYbYRTPuxo79POziYHYwMYf84LRQsBmi6e_o3kA',
        serviceWorkerRegistration: registration
      }).then((currentToken) => {
        if (!currentToken) {
          // Show permission request UI
          console.log('No registration token available. Request permission to generate one.');
        }
      })
      .catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
      })

    }).catch(function (err) {
      console.log('Service worker registration failed, error:', err);
    });
}

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <Router>
        <SnackbarProvider maxSnack={3}>
          <App />
        </SnackbarProvider>
      </Router>
    </ThemeProvider>
  </Provider>
  , document.getElementById('root'))

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
