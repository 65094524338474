import * as types from '../types'

export const createProjectMiddleware = (dispatchEvent) => {
  let socket = null

  const onOpen = store => (event) => {
    console.log('websocket open', event.target.url);
    store.dispatch({
      type: types.PROJECT_SET_WS_CONNECTED,
      host: event.target.url,
    })

    // store.dispatch(wsConnected(event.target.url));
  }

  const onClose = store => () => {
    console.log('websocket close')
    socket = null
    store.dispatch({
      type: types.PROJECT_SET_WS_DISCONNECTED,
    })
  }

  const onMessage = store => (event) => {
    const payload = JSON.parse(event.data);
    console.log('receiving server message');

    if (dispatchEvent)
      dispatchEvent("project", payload)

    // alert("onMessage: " + payload.type + " with Data: " + JSON.stringify(payload.data, null, 2))

    /**
     * Haekal: 12/10/2022
     * Comment: unused. switch should be in App.js' event listener
     */
    // switch (payload.type) {

    //   default:
    //     break
    // }
  }

  // the middleware part of this function
  return store => next => action => {
    switch (action.type) {
      case types.PROJECT_ACTION_WS_CONNECT:
        if (socket !== null) {
//          socket.close();
          break
        }        

//        alert(action.host)
        // connect to the remote host
        socket = new WebSocket(action.host)
        
        store.dispatch({
          type: types.PROJECT_SET_WS_CONNECTING,
          host: action.host
        })
        // store.dispatch(wsStateChanged(WSSTATE.Connecting))

        // websocket handlers
        socket.onmessage = onMessage(store)
        socket.onclose = onClose(store)
        socket.onopen = onOpen(store)

        break;
      
      case types.PROJECT_ACTION_WS_DISCONNECT:
        if (socket !== null) {
          socket.close();
        }
        socket = null;
        
        console.log('websocket closed')
        store.dispatch({
          type: types.PROJECT_SET_WS_DISCONNECTED,
        })
        
        break

      default:
        console.log('the next action:', action)
        return next(action)
    }
  }
}

export default createProjectMiddleware