import React, { useState, useEffect, useContext } from 'react'
import { useLocation, useHistory } from "react-router-dom"
import { Link } from 'react-router-dom'
import useStyles from './styles'
import clsx from 'clsx'
import { MenuPopover } from 'shared/ui'
import {
  BottomNavigation,
  BottomNavigationAction,
} from '@material-ui/core'

import {
  Apps as AppsIcon,
  ExpandMore,
  ExpandLess
} from '@material-ui/icons'

const BottomNavBar = ({ menuItems }) => {
  const classes = useStyles()
  const history = useHistory()

  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null)
  const [popoverMenuItems, setPopoverMenuItems] = useState(null)

  const showPopoverMenu = (target, menuItems) => {
    setPopoverMenuItems(menuItems)
    setPopoverAnchorEl(target)
  }

  const hidePopoverMenu = () => {
    setPopoverMenuItems(null)
    setPopoverAnchorEl(null);
  }

  return (
    <div className={classes.bottomNavBar}>
      <BottomNavigation
        showLabels
        value={menuItems.findIndex(m => m.selected)}
      >
        {menuItems.map(item =>
          <BottomNavigationAction
            label={(
              <div className={classes.bottomNavTitle}>
                {item.title}
                {item.actionItems?.length > 1 && (
                  <div className={classes.bottomNavDropUp}>
                    <ExpandLess />
                  </div>
                )}
              </div>
            )}
            icon={React.createElement(item.icon)}
            onClick={(event) => {
              if (item.actionItems?.length > 1) {
                showPopoverMenu(event.target, item.actionItems)
              }
              else if (item.linkTo)
                history.push(item.linkTo)
              else if (item.onItemClick)
                item?.onItemClick()
            }}
            classes={{
              root: classes.bottomNavBarActionItem
            }}
          />
        )}
        {popoverMenuItems && popoverAnchorEl &&
          <MenuPopover
            menuItems={popoverMenuItems}
            open={true}
            anchorEl={popoverAnchorEl}
            onClose={hidePopoverMenu}
          />
        }
      </BottomNavigation>
    </div>
  )
}

export default BottomNavBar