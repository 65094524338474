import React from 'react'
import {
  ListSubheader as MuiListSubheader,
} from '@material-ui/core'
import useStyles from './styles'

export const ListHeader = (props) => {    
  return (
    <MuiListSubheader {...props} />
  )
}

export default ListHeader