import { Component } from 'react'

import { withRouter } from 'react-router-dom'

// Help scroll to top on page change
class ScrollToTop extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      if (this.props.location.search) {
        let sp = new URLSearchParams(this.props.location.search)
        if (sp.get('popup') == 1)
          return
      }

      if (prevProps.location.search) {
        let sp = new URLSearchParams(prevProps.location.search)
        if (sp.get('popup') == 1)
          return
      }
  
      window.scrollTo(0, 0)
    }
  }
  
  render() {
    return this.props.children
  }
}

export default withRouter(ScrollToTop)