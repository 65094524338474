import clsx from 'clsx'
import {
  Text,
  Avatar,
} from '.'
import {
  AccountCircle as ContactIcon,
  Business as CompanyIcon,
} from '@material-ui/icons'
import useStyles from './styles'

export const Header = ({
  isFetching,
  avatar,
  titleVariant = "title1",
  title,
  subtitle,
  sideContent,
  direction = "normal",
  useColumnLayout = false,
  borderBottom = false,
  scrollXHeader = false
}) => {
  const classes = useStyles()

  return (
    <div 
      className={clsx(classes.header, useColumnLayout && classes.headerCenteredContent, borderBottom && classes.headerBorderBottom)}
      style={{
        overflowX: scrollXHeader ? 'auto' : 'none'
      }}
    >
      <div className={clsx(
        classes.headerMainContent,
        direction === "centered" && classes.headerCenteredContent,
        direction === "lefted" && classes.headerLeftedContent
      )}>
        {avatar &&
          <div className={clsx(classes.headerAvatarWrapper, useColumnLayout && classes.centeredAvatar)}>
            {avatar === "contact" &&
              <ContactIcon style={{ fontSize: "5rem", color: "#ddd" }} />
            }
            {avatar === "company" &&
              <CompanyIcon style={{ fontSize: "5rem", color: "#ddd" }} />
            }
            {avatar == "default" &&
              <Avatar size={10}>{avatar}</Avatar>
            }
          </div>
        }
        <div className={clsx(
          classes.headerTitle,
          avatar && classes.headerTitleWithAvatar,
          useColumnLayout && direction !== 'lefted' && classes.centeredTitle,
          useColumnLayout && direction == 'lefted' && classes.leftedTitle
        )}>
          <Text variant="h4" className={titleVariant == "title2" ? classes.pageTitle2Text : classes.pageTitleText} gutterBottom={Boolean(subtitle)}>
            {title}
          </Text>
          {subtitle &&
            <div className={classes.headerSubtitle}>
              {subtitle}
            </div>
          }
        </div>
      </div>
      {sideContent &&
        <div className={classes.headerSideContent}>
          {sideContent}
        </div>
      }
    </div>
  )
}

export default Header